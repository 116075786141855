import { Category } from '@/lib/definitions';

const cateogoriesObject = {
  'hr-contracts': 'HR Contracts',
  'commercial-contracts': 'Commercial Contracts',
  'real-estate': 'Real Estate',
  'ip-and-trademarks': 'IP and Trademarks',
  'board-matters': 'Board Matters',
  funding: 'Funding',
};

type CategoryValues = keyof typeof cateogoriesObject;

const categories = Object.entries(cateogoriesObject).map(([value, label]) => ({
  label,
  value,
}));

export const defaultCategories = {
  'hr-contracts': {
    id: 'hr-contracts',
    name: 'HR Contracts',
    description: 'HR Contracts category',
  },
  'commercial-contracts': {
    id: 'commercial-contracts',
    name: 'Commercial Contracts',
    description: 'Commercial Contracts category',
  },
  'real-estate': {
    id: 'real-estate',
    name: 'Real Estate',
    description: 'Real Estate category',
  },
  'ip-and-trademarks': {
    id: 'ip-and-trademarks',
    name: 'IP and Trademarks',
    description: 'IP and Trademarks category',
  },
  'board-matters': {
    id: 'board-matters',
    name: 'Board Matters',
    description: 'Board Matters category',
  },
  funding: {
    id: 'funding',
    name: 'Funding',
    description: 'Funding category',
  },
} as const;

export const defaultCategoriesArray = Object.entries(defaultCategories).map(
  ([_, categoryData]) => ({
    ...categoryData,
  }),
) as Category[];

export default categories;
export { cateogoriesObject };
export type { CategoryValues };
