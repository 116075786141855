import { Button, Modal, TagInput } from '@/components/ui';
import { Controller, useForm } from 'react-hook-form';
import React from 'react';
import { FileTextIcon } from '@radix-ui/react-icons';
import { useControllableState } from '@/lib/hooks/state';
import { usePromise } from '@/lib/hooks';
import { Doc, VegaFile } from '@/lib/definitions';
import { isEmail } from 'validator';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown';
import { ChevronDown } from 'lucide-react';
import { shareFile } from '@/features/company/actions/files';
import { getExtension } from '@/lib/utils/path';
import { FilePreview } from './file-preview';
import { Form } from '@/components/ui/form';
import { useVendor } from '@/features/company/hooks/use-vendor';

type ShareFileModalProps = {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  children?: React.ReactNode;
  files: Doc[];
};

const accessDescription = {
  view: {
    title: 'Can view',
    description: 'Can view the document',
  },
  edit: {
    title: 'Can edit',
    description: 'Can comment and suggest changes to the document',
  },
  sign: {
    title: 'Can sign',
    description: 'Can only sign the document',
  },
};

const schema = z.object({
  emails: z
    .array(z.string().email('Please enter a valid email address'))
    .min(1, 'Please enter at least one email address'),
  items: z.array(
    z.object({
      id: z.string(),
      file: z.object({
        id: z.string(),
        name: z.string(),
        type: z.literal('file'),
        createdAt: z.string().or(z.number()),
        storagePath: z.string(),
      }),
      access: z.enum(['view', 'edit', 'sign']),
    }),
  ),
});

type DTO = z.infer<typeof schema>;

export const ShareFileModal = (props: ShareFileModalProps) => {
  const [vendor] = useVendor();
  const [open, onOpenChange] = useControllableState({
    prop: props.open,
    onChange: props.onOpenChange,
    defaultProp: false,
  });

  const form = useForm<DTO>({
    resolver: zodResolver(schema),
    defaultValues: {
      emails: [],
      items: props.files.map((file) => ({
        id: file.id,
        file: {
          id: file.id,
          name: file.name,
          type: file.type,
          createdAt: file.createdAt,
          storagePath: file.type === 'file' ? file.storagePath : undefined,
        },
        access: 'view',
      })),
    },
  });

  const [share, isSubmitting, _token, _error, status, resetPromise] = usePromise(
    async (data: DTO) => {
      const items = data.items.map((item) => ({
        id: item.id,
        access: item.access,
      }));
      await shareFile(items, data.emails, vendor?.branding?.logoUrl ? vendor?.branding?.logoUrl : '');
    },
  );

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      form.reset();
      resetPromise();
    }
    onOpenChange(open);
  };

  return (
    <Modal.Root open={open} onOpenChange={handleOpenChange}>
      {props.children && <Modal.Trigger asChild>{props.children}</Modal.Trigger>}
      <Modal.Content>
        <Modal.Header>
          <div className='flex gap-4 items-center'>
            <FileTextIcon className='w-7 h-7 text-primary' />
            <Modal.Title>Share with external party</Modal.Title>
          </div>
        </Modal.Header>
        {status === 'success' ? (
          <div className='px-8 space-y-12'>
            <Modal.Description>
              The file has been shared with the recipient. They will receive an email with a link to
              the document.
            </Modal.Description>
            <div className='flex justify-end space-x-2 mt-4 sticky bottom-0 bg-background py-6 border-t border-muted'>
              <Modal.Close asChild>
                <Button size='lg' variant='outline'>
                  Close
                </Button>
              </Modal.Close>
            </div>
          </div>
        ) : (
          <Form.Root {...form}>
            <form onSubmit={form.handleSubmit(share)}>
              <Modal.Body className='grid gap-4'>
                <Modal.Description>
                  Shares a link to the document with an external party. Add emails by pressing enter
                  into the input.
                </Modal.Description>
                <div className='grid gap-4'>
                  <Form.Field
                    name='emails'
                    control={form.control}
                    render={({ field }) => (
                      <Form.Item>
                        <Form.Label>Email addresses</Form.Label>
                        <TagInput
                          value={field.value}
                          onChange={(value) => field.onChange(value)}
                          placeholder='Add email'
                          onValidate={isEmail}
                        />
                        <Form.Message />
                      </Form.Item>
                    )}
                  />
                  <Form.Field
                    name='items'
                    control={form.control}
                    render={({ field: itemField }) => (
                      <Form.Item>
                        <Form.Label>Manage access</Form.Label>
                        <div className='grid gap-1'>
                          {itemField.value.map((item, index) => (
                            <FilePreview
                              key={item.id}
                              file={item.file as VegaFile}
                              onClose={() =>
                                itemField.onChange(
                                  itemField.value.filter((it) => it.id !== item.id),
                                )
                              }
                            >
                              <Controller
                                name={`items.${index}.access`}
                                key={item.id}
                                control={form.control}
                                render={({ field }) => (
                                  <DropdownMenu key={index}>
                                    <DropdownMenuTrigger asChild>
                                      <Button
                                        variant='ghost'
                                        size='sm'
                                        className='text-muted-foreground'
                                      >
                                        {field.value === 'view'
                                          ? 'Can view'
                                          : field.value === 'edit'
                                            ? 'Can edit'
                                            : 'Can sign'}
                                        <ChevronDown />
                                      </Button>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent className='w-64'>
                                      <DropdownMenuLabel>Share link access</DropdownMenuLabel>
                                      <DropdownMenuSeparator />
                                      {Object.entries(accessDescription).map(([key, value]) => {
                                        // only pdf can be signed
                                        if (
                                          item.file?.type === 'file' &&
                                          getExtension(item.file.storagePath) !== 'pdf' &&
                                          key === 'sign'
                                        ) {
                                          return null;
                                        }
                                        // pdf cannot be edited
                                        if (
                                          item.file?.type === 'file' &&
                                          getExtension(item.file.storagePath) === 'pdf' &&
                                          key === 'edit'
                                        ) {
                                          return null;
                                        }

                                        return (
                                          <DropdownMenuItem
                                            key={key}
                                            onSelect={() => field.onChange(key)}
                                          >
                                            <div>
                                              <div className='font-medium'>{value.title}</div>
                                              <div className='text-xs text-muted-foreground'>
                                                {value.description}
                                              </div>
                                            </div>
                                          </DropdownMenuItem>
                                        );
                                      })}
                                    </DropdownMenuContent>
                                  </DropdownMenu>
                                )}
                              />
                            </FilePreview>
                          ))}
                        </div>
                        <Form.Message />
                      </Form.Item>
                    )}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Modal.Close asChild>
                  <Button size='lg' variant='outline'>
                    Cancel
                  </Button>
                </Modal.Close>
                <Button size='lg' type='submit' loading={isSubmitting}>
                  Share
                </Button>
              </Modal.Footer>
            </form>
          </Form.Root>
        )}
      </Modal.Content>
    </Modal.Root>
  );
};
