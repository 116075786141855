import { Outlet, RouteObject } from 'react-router-dom';
import { useEffect } from 'react';
import {
  AppLayout,
  AppContentLayout,
  AppHeaderLayout,
  PlainLayout,
  PlainLayoutProps,
} from '@/components/layouts';
import { SuspenseWithPerf } from 'reactfire';
import { UIProvider } from '@/providers/ui';
import { lazyImport } from '@/lib/utils';
import { Suspense, useCallback } from 'react';
import { ACL } from '@/features/company/components';
import { AccessDenied } from '@/components/ui';
import { ErrorPage } from '@/features/misc/error-page';
import { CompanySidebar } from './components/sidebar';
import { Uploader } from '@/features/company/providers/uploader';
import { Toaster } from '@/providers/toaster';
import { useVendorStyles } from './hooks/use-vendor-styles';
import { CompanyHeader } from './components/header';
import { RequireUser } from '@/providers/user';
import { RequireAuth } from '@/providers/auth';
import { Fallback } from '@/components/fallback';
import { RequireCompany } from './providers/company';
import { ActivityProvider } from '@/providers/activity';
import { logoutUser } from '@/actions/auth';
import { CompanyRoutePermission } from './data/route';
import { useVendor } from './hooks/use-vendor';
import { updateFavicon, updateTitle } from '@/lib/utils';

const Protected = ({ children }: { children: React.ReactNode }) => {
  useVendorStyles();
  const [vendor] = useVendor();

  const handleLogout = useCallback(async () => {
    const logoutRedirectUrl = vendor?.branding?.homeUrl ? vendor?.branding?.homeUrl : '/login';
    if(vendor) {
      logoutUser(logoutRedirectUrl, true);
    }else {
      logoutUser(logoutRedirectUrl);
    }
  }, [vendor]);

    useEffect(() => {
      if (vendor?.branding.favicon && vendor?.branding.webTitle) {
        updateFavicon(vendor.branding.favicon);
        updateTitle(vendor.branding.webTitle)
      } else {
        updateFavicon(
          'https://firebasestorage.googleapis.com/v0/b/vegabymypocketcounsel.appspot.com/o/assets%2Fmpc.png?alt=media&token=d1b7a7b4-8b66-47aa-9d52-e2552d0b8f92',
        );
      }
    }, [vendor]);

  return (
    <SuspenseWithPerf fallback={<h1>Loading...</h1>} traceId='vega-user-wait'>
      <RequireAuth>
        <RequireUser>
          <RequireCompany>
            <ActivityProvider logout={handleLogout}>{children}</ActivityProvider>
          </RequireCompany>
        </RequireUser>
      </RequireAuth>
    </SuspenseWithPerf>
  );
};
const App = () => {
  return (
    <Protected>
      <UIProvider>
        <AppLayout sidebar={<CompanySidebar />}>
          <AppHeaderLayout>
            <CompanyHeader />
          </AppHeaderLayout>
          <AppContentLayout>
            <Suspense fallback={<Fallback />}>
              <Outlet />
            </Suspense>
          </AppContentLayout>
        </AppLayout>
      </UIProvider>
    </Protected>
  );
};

const Plain = (props: PlainLayoutProps & { preventRedirect?: boolean }) => {
  return (
    <SuspenseWithPerf fallback={<h1>Loading...</h1>} traceId='vega-user-wait'>
      <RequireAuth>
        <RequireUser isOptional={props.preventRedirect}>
          <RequireCompany isOptional={props.preventRedirect}>
            <PlainLayout {...props}>
              <Outlet />
            </PlainLayout>
          </RequireCompany>
        </RequireUser>
      </RequireAuth>
    </SuspenseWithPerf>
  );
};

const { Account } = lazyImport(() => import('@/features/company/pages'), 'Account');
const { Advice } = lazyImport(() => import('@/features/company/pages'), 'Advice');
const { Agreements } = lazyImport(() => import('@/features/company/pages'), 'Agreements');
const { Draft } = lazyImport(() => import('@/features/company/pages'), 'Draft');
const { Template } = lazyImport(() => import('@/features/company/pages'), 'Template');
const { Templates } = lazyImport(() => import('@/features/company/pages'), 'Templates');
const { Faqs } = lazyImport(() => import('@/features/company/pages'), 'Faqs');
const { File } = lazyImport(() => import('@/features/company/pages'), 'File');
const { Files } = lazyImport(() => import('@/features/company/pages'), 'Files');
const { Home } = lazyImport(() => import('@/features/company/pages'), 'Home');
const { Member } = lazyImport(() => import('@/features/company/pages'), 'Member');
const { AcceptInvite } = lazyImport(() => import('@/features/company/pages'), 'AcceptInvite');
const { OnboardingStep1 } = lazyImport(() => import('@/features/company/pages'), 'OnboardingStep1');
const { OnboardingStep2 } = lazyImport(() => import('@/features/company/pages'), 'OnboardingStep2');
const { Lawyers } = lazyImport(() => import('@/features/company/pages'), 'Lawyers');
const { Payment } = lazyImport(() => import('@/features/company/pages'), 'Payment');
const { Tasks } = lazyImport(() => import('@/features/company/pages'), 'Tasks');
const { ClauseLibrary } = lazyImport(() => import('@/features/company/pages'), 'ClauseLibrary');
const { Tickets } = lazyImport(() => import('@/features/company/pages'), 'Tickets');
const { Approval } = lazyImport(() => import('@/features/company/pages'), 'Approval');
const { Team } = lazyImport(() => import('@/features/company/pages'), 'Team');
const { Shared } = lazyImport(() => import('@/features/company/pages'), 'Shared');
const { Shares } = lazyImport(() => import('@/features/company/pages'), 'Shares');
const { Roles } = lazyImport(() => import('@/features/company/pages'), 'Roles');
const { AddNewCompany } = lazyImport(() => import('@/features/company/pages'), 'AddNewCompany');
const { CounterParties } = lazyImport(() => import('@/features/company/pages'), 'CounterParties');
const { Categories } = lazyImport(() => import('@/features/company/pages'), 'Categories');

const { NewTicketModalView } = lazyImport(
  () => import('@/features/company/components/views/tickets'),
  'NewTicketModalView',
);
const { ViewTicketModal } = lazyImport(
  () => import('@/features/company/components/views/tickets'),
  'ViewTicketModal',
);
const { EditTicketModal } = lazyImport(
  () => import('@/features/company/components/views/tickets'),
  'EditTicketModal',
);
const { MoveTicketModal } = lazyImport(
  () => import('@/features/company/components/views/tickets'),
  'MoveTicketModal',
);
const { DeleteTicketModal } = lazyImport(
  () => import('@/features/company/components/views/tickets'),
  'DeleteTicketModal',
);

const { NewBookingModal } = lazyImport(
  () => import('@/features/company/components/views/lawyers/new-booking-modal'),
  'NewBookingModal',
);
const { CallbackBookingModal } = lazyImport(
  () => import('@/features/company/components/views/lawyers/callback-booking-modal'),
  'CallbackBookingModal',
);
const { ScheduleBookingModal } = lazyImport(
  () => import('@/features/company/components/views/lawyers/schedule-booking-modal'),
  'ScheduleBookingModal',
);

const companyProtectedRoutes: RouteObject[] = [
  {
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: 'agreements',
        element: (
          <ACL permission={CompanyRoutePermission.Agreements} fallback={<AccessDenied />}>
            <Agreements />
          </ACL>
        ),
      },
      {
        path: 'categories',
        element: (
          <ACL permission={CompanyRoutePermission.Categories} fallback={<AccessDenied />}>
            <Categories />
          </ACL>
        ),
      },
      {
        path: 'tasks',
        element: (
          <ACL permission={CompanyRoutePermission.Tasks} fallback={<AccessDenied />}>
            <Tasks />
          </ACL>
        ),
      },
      {
        path: 'roles',
        element: (
          <ACL permission={CompanyRoutePermission.Roles} fallback={<AccessDenied />}>
            <Roles />
          </ACL>
        ),
      },
      {
        path: 'approval',
        element: (
          <ACL permission={CompanyRoutePermission.Approval} fallback={<AccessDenied />}>
            <Approval />
          </ACL>
        ),
      },
      {
        path: 'counterparties',
        element: (
          <ACL permission={CompanyRoutePermission.CounterParties} fallback={<AccessDenied />}>
            <CounterParties />
          </ACL>
        ),
      },
      {
        path: '/faqs',
        element: <Faqs />,
      },
      {
        path: 'files',
        element: (
          <ACL permission={CompanyRoutePermission.Files} fallback={<AccessDenied />}>
            <Outlet />
          </ACL>
        ),
        children: [
          { path: '', element: <Files /> },
          { path: ':id', element: <Files /> },
        ],
      },
      {
        path: 'advice',
        element: (
          <ACL permission={CompanyRoutePermission.Advice} fallback={<AccessDenied />}>
            <Advice />
          </ACL>
        ),
      },
      {
        path: 'experts',
        element: (
          <ACL permission={CompanyRoutePermission.Experts} fallback={<AccessDenied />}>
            <Lawyers />
          </ACL>
        ),
        children: [
          {
            path: ':lawyerId/new-booking',
            element: <NewBookingModal />,
          },
          {
            path: 'payment-callback',
            element: <CallbackBookingModal />,
          },
          {
            path: 'schedule/:bookingId',
            element: <ScheduleBookingModal />,
          },
        ],
      },
      {
        path: 'template',
        element: (
          <ACL permission={CompanyRoutePermission.Custom} fallback={<AccessDenied />}>
            <Templates />
          </ACL>
        ),
      },
      // Support for old route
      {
        path: 'custom',
        element: (
          <ACL permission={CompanyRoutePermission.Custom} fallback={<AccessDenied />}>
            <Templates />
          </ACL>
        ),
      },
      {
        path: 'tickets',
        element: (
          <ACL permission={CompanyRoutePermission.Tickets} fallback={<AccessDenied />}>
            <Tickets />
          </ACL>
        ),
        children: [
          {
            path: 'new',
            element: <NewTicketModalView />,
          },
          {
            path: ':ticketId',
            element: <ViewTicketModal />,
          },
          {
            path: 'edit/:ticketId',
            element: <EditTicketModal />,
          },
          {
            path: 'move/:ticketId',
            element: <MoveTicketModal />,
          },
          {
            path: 'delete/:ticketId',
            element: <DeleteTicketModal />,
          },
        ],
      },
      {
        path: 'members',
        element: (
          <ACL permission={CompanyRoutePermission.Members} fallback={<AccessDenied />}>
            <Outlet />
          </ACL>
        ),
        children: [
          { path: '', element: <Team /> },
          { path: ':memberId', element: <Member /> },
        ],
      },
      {
        path: '/clause-library',
        element: (
          <ACL permission={CompanyRoutePermission.ClauseLibrary} fallback={<AccessDenied />}>
            <ClauseLibrary />
          </ACL>
        ),
      },
      {
        path: 'account',
        element: (
          <ACL permission={CompanyRoutePermission.Account} fallback={<AccessDenied />}>
            <Account />
          </ACL>
        ),
        children: [
          { path: 'settings', index: true, element: <h1>Settings</h1> },
          { path: 'plan', element: <h1>Plan</h1> },
          { path: 'change-password', element: <h1>Change Password</h1> },
          { path: 'profile', element: <h1>Profile</h1> },
          { path: 'preferences', element: <h1>Notifications</h1> },
        ],
      },
    ],
  },
  {
    element: <Plain preventRedirect />,
    children: [
      { path: 'onboarding', element: <OnboardingStep1 /> },
      { path: 'onboarding/2', element: <OnboardingStep2 /> },
      { path: 'new-company', element: <AddNewCompany /> },
      { path: 'invite/:invitationId', element: <AcceptInvite /> },
    ],
  },
  {
    element: <Plain className='max-w-4xl' goBack />,
    children: [{ path: 'payment', element: <Payment /> }],
  },
  {
    element: (
      <Protected>
        <Outlet />
      </Protected>
    ),
    children: [
      { path: 'draft/:id', element: <Draft /> },
      { path: 'draft/:id/:step', element: <Draft /> },
      { path: 'template/:id', element: <Template /> },
      { path: 'file/:fileId', element: <File /> },
    ],
  },
  {
    element: <Outlet />,
    children: [
      {
        path: 'shared/:companyId/:shareId',
        element: <Shared />,
      },
      {
        path: 'shares/:shareId',
        element: <Shares />,
      },
    ],
  },
];

export const companyRoutes: RouteObject[] = [
  {
    element: (
      <>
        <Outlet />
        <Toaster>
          <Uploader />
        </Toaster>
      </>
    ),
    children: companyProtectedRoutes,
  },
];
