/* eslint-disable react/prop-types */
import { useControllableState } from '@/lib/hooks';
import { cn } from '@/lib/utils';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { X } from 'lucide-react';
import React, { PropsWithChildren, forwardRef } from 'react';

const Root = (props: DialogPrimitive.DialogProps) => <DialogPrimitive.Root {...props} />;

const Trigger = DialogPrimitive.Trigger;

const Portal = DialogPrimitive.Portal;

const Close = DialogPrimitive.Close;

Trigger.displayName = 'ModalTrigger';

const Overlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      'fixed inset-0 z-50 bg-black/80  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
      className,
    )}
    {...props}
  />
));
Overlay.displayName = DialogPrimitive.Overlay.displayName;

const Content = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <Portal>
    <Overlay />
    <DialogPrimitive.Content
      ref={ref}
      className={cn(
        'fixed left-[50%] top-[50%] z-50 grid w-full max-w-md translate-x-[-50%] translate-y-[-50%] border bg-background shadow-lg duration-500 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg',
        'max-h-[85vh] overflow-auto',
        className,
      )}
      {...props}
    >
      {children}
    </DialogPrimitive.Content>
  </Portal>
));

Content.displayName = DialogPrimitive.Content.displayName;

const Header = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      {...props}
      ref={ref}
      className={cn(
        'flex flex-col p-6 pb-3 space-y-1.5 text-center sm:text-left sticky top-0 bg-background z-10',
        className,
      )}
    >
      {props.children}
      <DialogPrimitive.Close className='absolute z-50 right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground'>
        <X className='h-4 w-4' />
        <span className='sr-only'>Close</span>
      </DialogPrimitive.Close>
    </div>
  ),
);
Header.displayName = 'Modal.Header';

const Body = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('px-6 pt-3 pb-6', className)} {...props} />
  ),
);
Body.displayName = 'Modal.Body';

const Footer = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'flex flex-col-reverse sm:flex-row sm:justify-between sm:space-x-2 px-6 py-4 sticky bottom-0 z-10 bg-background border-t border-muted',
        className,
      )}
      {...props}
    />
  ),
);
Footer.displayName = 'Modal.Footer';

{
  /* <div ref={ref} className={cn('flex items-center p-8 pt-4 justify-end gap-2 sticky bottom-0 z-10 bg-background border-t border-muted', className)} {...props} /> */
}

const Title = (props: DialogPrimitive.DialogTitleProps) => (
  <DialogPrimitive.Title
    {...props}
    className={cn('text-lg leading-4 font-medium text-foreground', props.className)}
  />
);

const Description = (props: DialogPrimitive.DialogDescriptionProps) => (
  <DialogPrimitive.Description
    {...props}
    className={cn('text-sm font-normal text-muted-foreground', props.className)}
  />
);

const Modal = {
  Root,
  Trigger,
  Content,
  Header,
  Body,
  Footer,
  Title,
  Description,
  Close,
};

type ModalProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
};

/**
 * Higher-order component that wraps a component with modal functionality.
 * @template T - The type of props for the wrapped component.
 * @param {React.FC<T>} Component - The component to be wrapped.
 * @returns {React.FC<T>} - The wrapped component with modal functionality.
 */
const withModal = <T extends object>(ContentComponent: React.FC<T & ModalProps>) => {
  const ModalComponent = (props: T & Partial<ModalProps> & PropsWithChildren) => {
    const [open, onOpenChange] = useControllableState({
      prop: props.open,
      onChange: props.onOpenChange,
      defaultProp: false,
    });
    return (
      <Root open={open} onOpenChange={onOpenChange}>
        {props.children && <Trigger asChild>{props.children}</Trigger>}
        <ContentComponent {...props} open={!!open} onOpenChange={onOpenChange} />
      </Root>
    );
  };
  ModalComponent.displayName = ContentComponent.displayName;
  return ModalComponent;
};

export { Modal, withModal };
