import { defaultRoles, Permission } from '@/data/permission';
import { useMemo } from 'react';
import { useCompany } from '../providers/company';
import { Member, Role } from '@/lib/definitions';
import { useMember } from './use-member';
import { ensureArray } from '@/lib/utils';

const backwardCompatibilityMap = {
  '1': 'admin',
  '2': 'member',
};

const useRoles = (consumerName: string) => {
  const { activeCompany: company } = useCompany(consumerName);

  const roles = useMemo(() => {
    // merge defaultRoles and company roles
    const companyRoles = company?.roles ?? {};

    const roles = Object.values(defaultRoles);

    Object.values(companyRoles).forEach((role) => {
      // Backward compatibility - They are same role as admin and member
      // We don't want to show them twice
      // We should remove them
      if (role.id in backwardCompatibilityMap) return;
      roles.push(role);
    });

    return roles;
  }, [company.roles]);

  const normalizeRoleId = (roleId: string) => {
    if (roleId in backwardCompatibilityMap) {
      return backwardCompatibilityMap[roleId as keyof typeof backwardCompatibilityMap];
    }
    return roleId;
  };

  const getRole = (roleId: string): Role | undefined => {
    roleId = normalizeRoleId(roleId);
    if (roleId in defaultRoles) {
      return defaultRoles[roleId as keyof typeof defaultRoles];
    }
    return company?.roles?.[roleId];
  };

  const getRoleByLabel = (label: string) => {
    return Object.values(company?.roles ?? {}).find(
      (role) => role.name.toLowerCase() === label.toLowerCase(),
    );
  };

  return { data: roles, normalizeRoleId, getRole, getRoleByLabel };
};

const usePermissions = (consumerName: string, memberId: string) => {
  const member = useMember(consumerName, memberId) as Member;
  const { getRole } = useRoles(consumerName);

  return useMemo(() => {
    const roleIds = ensureArray(member.role);
    const permissions = roleIds.reduce<Permission[]>((acc, roleId) => {
      const role = getRole(roleId);
      if (role && role.permissions) {
        acc.push(...(role.permissions as Permission[]));
      }
      return acc;
    }, []);

    return permissions;
  }, [member.role]);
};

export { useRoles, usePermissions };
