import { Permission } from '@/data/permission';
import {
  Bolt,
  FileStack,
  FileText,
  LayoutDashboard,
  ListChecks,
  Lock,
  FileStackIcon,
  PencilRuler,
  Rocket,
  ScrollText,
  Ticket,
  UserRound,
  Users2,
  CircleHelp,
  Mail,
  BarChartBig
} from 'lucide-react';
import { useMemo } from 'react';
import { hasPermission } from '../lib/permission';
import {
  AppSidebar,
  AppSidebarContent,
  AppSidebarGroup,
  AppSidebarHeader,
  AppSidebarItemData,
  AppSidebarItemGroupData,
  AppSidebarMenu,
} from '@/components/app-sidebar';
import { useUser } from '@/providers/user';
import { usePermissions } from '../hooks/use-roles';
import { CompanyRoutePermission } from '../data/route';
import { useCompany } from '../providers/company';
import { useVendor } from '@/features/company/hooks/use-vendor';

type Item = AppSidebarItemData & {
  permissions?: Permission[];
};

type ItemGroup = AppSidebarItemGroupData & {
  items: Item[];
};

type LinkItem = Item | ItemGroup;

// Feature to link key mapping
const featureToLinkKeyMap: { [key: string]: string } = {
  'doc-automation': 'agreement',
  'task-management': 'task',
  'document-storage': 'file',
  'realtime-status': 'ticket',
  'legal-marketplace': 'legal',
  'custom-contract': 'custom',
  'approval-flow': 'approval',
  'team-management': 'team',
  'role-management': 'role',
  'account-management': 'account',
  'ticket-management': 'ticket',
  dashboard: 'dashboard',
  'clause-management': 'clause',
  'counter-parties': 'counterparty',
  'categories': 'categories',
};

// Main links
const generalItems: LinkItem[] = [
  { id: 'dashboard', name: 'Dashboard', icon: LayoutDashboard, to: '/' },
  {
    id: 'agreement',
    name: 'Agreement Automation',
    icon: FileText,
    items: [
      {
        id: 'agreement',
        name: 'Agreement Entries',
        icon: FileText,
        to: '/agreements',
        permissions: CompanyRoutePermission.Agreements,
      },
      {
        id: 'approval',
        name: 'Approval Flow',
        icon: ListChecks,
        to: '/approval',
        permissions: CompanyRoutePermission.Approval,
      },
      {
        id: 'counterparty',
        name: 'Parties Library',
        icon: Users2,
        to: '/counterparties',
        permissions: CompanyRoutePermission.CounterParties,
      },
      {
        id: 'clause',
        name: 'Clause Library',
        icon: FileStackIcon,
        to: '/clause-library',
        permissions: CompanyRoutePermission.ClauseLibrary,
      },
      {
        id: 'categories',
        name: 'Categories Library',
        icon: BarChartBig,
        to: '/categories',
        permissions: CompanyRoutePermission.Categories,
      },
    ],
  },
  {
    id: 'task',
    name: 'Tasks',
    icon: Rocket,
    to: '/tasks',
    permissions: CompanyRoutePermission.Tasks,
  },
  {
    id: 'file',
    name: 'File Storage',
    icon: FileStack,
    to: '/files',
    permissions: CompanyRoutePermission.Files,
  },
  {
    id: 'team',
    name: 'Team',
    icon: UserRound,
    items: [
      {
        id: 'members',
        name: 'Members',
        icon: Users2,
        to: '/members',
        permissions: CompanyRoutePermission.Members,
      },
      {
        id: 'roles',
        name: 'Permissions',
        icon: Lock,
        to: '/roles',
        permissions: CompanyRoutePermission.Roles,
      },
    ],
  },
  // { key: 'role', name: 'Roles', icon: Lock, to: '/roles' },
  // { key: 'legal', name: 'Legal Marketplace', icon: Rocket, to: '/legal' },
  {
    id: 'ticket',
    name: 'Tickets',
    icon: Ticket,
    to: '/tickets',
    permissions: CompanyRoutePermission.Tickets,
  },
  {
    id: 'custom',
    name: 'Custom Documents',
    icon: PencilRuler,
    to: '/custom',
    permissions: CompanyRoutePermission.Custom,
  },
];

const accountItems: Item[] = [
  {
    name: 'Account',
    to: '/account',
    icon: Bolt,
    id: 'account',
    permissions: CompanyRoutePermission.Account,
  },
];

const HelpAndSupport = () => {
  const [vendor] = useVendor();

  const helpAndSupportItems: Item[] = [
    {
      name: 'Support',
      to: vendor ? `mailto:${vendor?.branding.supportEmail}` : 'mailto:vega@lawyeredup.co.za',
      icon: Mail,
      id: 'support',
      target: '_blank',
    },
    {
      name: 'FAQs',
      to: '/faqs',
      icon: CircleHelp,
      id: 'faqs',
    },
    ...(vendor?.disable
      ? []
      : [
          {
            name: 'Terms of Use',
            to: 'https://firebasestorage.googleapis.com/v0/b/vegabymypocketcounsel.appspot.com/o/assets%2FCompany%20Terms%20of%20Service%20Lawyered%20Up%20powered%20by%20Vega.pdf?alt=media&token=6a08d542-4af3-41a7-bbeb-6c8dd390df16',
            icon: ScrollText,
            id: 'terms',
            target: '_blank',
          },
          {
            name: 'Privacy Policy',
            to: 'https://firebasestorage.googleapis.com/v0/b/vegabymypocketcounsel.appspot.com/o/assets%2FLawyered%20Up%20Vega%20Privacy%20Policy.pdf?alt=media&token=b4f20b8b-42bc-4d08-ba18-039e861d4147',
            icon: Lock,
            id: 'privacy',
            target: '_blank',
          },
        ]),
  ];

  return helpAndSupportItems;
};

const filterItems = (
  items: LinkItem[],
  permissions: Permission[],
  companyPlan?: string,
  companyFeatures?: string[],
): LinkItem[] => {
  return items
    .map((item) => {
      if ('items' in item) {
        const filteredItems = filterItems(item.items, permissions, companyPlan, companyFeatures);

        // Handle nested items based on the company plan
        if (item.id === 'agreement') {
          if (companyPlan === 'basic') {
            const allowedItems = filteredItems.filter(
              (subItem) => subItem && !['approval', 'clause'].includes(subItem.id),
            );

            return allowedItems.length > 0 ? { ...item, items: allowedItems } : null;
          }
        }

        return filteredItems.length ? { ...item, items: filteredItems } : null;
      }

      // Custom handling for the "ticket" feature
      if (item.id === 'ticket') {
        return companyFeatures?.includes('ticket-management') ? item : null;
      }
      // Custom plan feature handling
      if (companyPlan === 'custom') {
        return companyFeatures?.some(
          (feature) =>
            // Check both direct and mapped feature keys
            featureToLinkKeyMap[feature] === item.id ||
            // Special handling for team and nested team items
            (feature === 'team-management' &&
              (item.id === 'team' ||
                (item.id === 'members' && item.permissions) ||
                (item.id === 'roles' && item.permissions))),
        )
          ? item
          : null;
      }

      // Free plan allowed features
      if (!companyPlan || companyPlan === 'free') {
        const allowedFreeFeatures = [
          'dashboard',
          'agreement',
          'task',
          'file',
          'team',
          'role',
          'account',
        ];
        return allowedFreeFeatures.includes(item.id) ? item : null;
      }

      if (companyPlan === 'premium') {
        const excludedPremiumFeatures = ['ticket', 'custom'];
        return !excludedPremiumFeatures.includes(item.id) ? item : null;
      }

      // General permission and plan-based filtering
      const isAllowedByPlan =
        companyPlan === 'basic'
          ? !['approval', 'clause', 'ticket', 'custom'].includes(item.id)
          : true;

      return hasPermission(permissions, item.permissions ?? []) && isAllowedByPlan ? item : null;
    })
    .filter(Boolean) as LinkItem[];
};

export const CompanySidebar = () => {
  const { user } = useUser('CompanySidebar');
  const { activeCompany: company } = useCompany('CompanySidebar');
  const permissions = usePermissions('CompanySidebar', user.uid);

  const filteredGeneralItems = useMemo(() => {
    return filterItems(generalItems, permissions, company?.plan?.type, company?.plan?.features);
  }, [permissions, company?.plan]);

  const filteredAccountItems = useMemo(() => {
    return filterItems(accountItems, permissions, company?.plan?.type, company?.plan?.features);
  }, [permissions, company?.plan]);

  return (
    <AppSidebar>
      <AppSidebarHeader />
      <AppSidebarContent>
        <AppSidebarGroup title='General'>
          <AppSidebarMenu items={filteredGeneralItems} />
        </AppSidebarGroup>
        {filteredAccountItems.length > 0 && (
          <AppSidebarGroup title='Accounts & Settings'>
            <AppSidebarMenu items={filteredAccountItems} />
          </AppSidebarGroup>
        )}
        <AppSidebarGroup title='Help' className='mt-auto'>
          <AppSidebarMenu items={HelpAndSupport()} />
        </AppSidebarGroup>
      </AppSidebarContent>
    </AppSidebar>
  );
};
