import { forwardRef } from 'react';
import { Popover } from './popover';
import { Button } from './button';
import { cn } from '@/lib/utils';
import { format } from 'date-fns';
import { Calendar } from './calendar';
import { CalendarIcon } from 'lucide-react';

type DatePickerProps = React.HTMLAttributes<HTMLButtonElement> & {
  value: Date | null;
  onValueChange: (value: Date) => void;
};

export const DatePicker = forwardRef<HTMLButtonElement, DatePickerProps>(
  ({ value, onValueChange, ...props }, ref) => {
    return (
      <Popover>
        <Popover.Trigger asChild>
          <Button
            {...props}
            ref={ref}
            variant={'outline'}
            className={cn(
              'w-full justify-start text-left font-normal rounded-xl',
              !value && 'text-muted-foreground',
              props.className,
            )}
          >
            <CalendarIcon className='w-4 h-4 mr-2' />
            {value ? format(value, 'PPP') : <span>Pick a date</span>}
          </Button>
        </Popover.Trigger>
        <Popover.Content className='w-auto p-0' align='start'>
          <Calendar
            mode='single'
            selected={value ?? undefined}
            onSelect={onValueChange}
            required={true}
          />
        </Popover.Content>
      </Popover>
    );
  },
);

DatePicker.displayName = 'DatePicker';
