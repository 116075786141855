import * as F from '@/lib/firebase';
import { UserId, VegaFile } from '@/lib/definitions';
import { httpsCallable } from 'firebase/functions';
import { collection, doc, updateDoc, addDoc } from 'firebase/firestore';
import { getDownloadURL, ref as sRef, uploadBytes } from 'firebase/storage';

import axios from 'axios';

export interface AccessAlert {
  type: string;
  documentName: string;
  docId: string;
  targetEmails?: string[];
}

export interface SaveFileAsNewParams {
  companyId: string;
  parentId?: string;
  name: string;
  type: 'pdf' | 'docx';
  accessBy: string[];
  blob: Blob;
  mode: string;
  createdBy: string;
}

export const getFileCollection = (companyId: string) =>
  collection(F.firestore, 'companies', companyId, 'files');

export const getFileDocument = (companyId: string, fileId: string) =>
  doc(getFileCollection(companyId), fileId);

type UploadFileParams = {
  name: string;
  parentId: string;
  accessBy: string[];
  file: File;
  mode?: string;
  onProgress?: (progress: number) => void;
};

export const uploadFile = async ({
  name,
  parentId,
  accessBy,
  file,
  mode = 'uploaded',
  onProgress,
}: UploadFileParams) => {
  const getURL = httpsCallable(F.functions, 'file-getUploadURL');
  const getURLResponse = await getURL({ name, parentId, accessBy, mode });

  const { uploadURL, fileId } = getURLResponse.data as { uploadURL: string; fileId: string };

  // Ensure upload is done before proceeding
  const uploadPromise = axios.put(uploadURL, file, {
    onUploadProgress: (progressEvent) => {
      const progress = progressEvent.total ? (progressEvent.loaded / progressEvent.total) * 100 : 0;
      onProgress?.(progress);
    },
  });

  try {
    // Wait for the upload to complete
    await uploadPromise;
    console.log('File uploaded successfully!');
  } catch (error) {
    console.error('Error during file upload:', error);
    throw error;
  }

  return fileId; // Return fileId only after upload is complete
};

export const deleteFile = async (companyId: string, fileId: string) => {
  const deleteFileFn = httpsCallable(F.functions, 'file-deleteFile');
  await deleteFileFn({ companyId, fileId });
};

type CreateFolderParams = {
  name: string;
  parentId: string;
  accessBy: string[];
  mode: string;
};

type CreateFolderResponse = {
  id: string;
};

export const createFolder = async (
  name: string,
  parentId: string,
  accessBy: string[] = [],
  options: { mode: string } = { mode: 'uploaded' },
) => {
  const createFolderFn = httpsCallable<CreateFolderParams, CreateFolderResponse>(
    F.functions,
    'file-createFolder',
  );
  const response = await createFolderFn({
    name,
    parentId,
    accessBy,
    mode: options.mode,
  });
  return response.data.id;
};

export const renameFile = async (companyId: string, fileId: string, name: string) => {
  const fileRef = getFileDocument(companyId, fileId);
  await updateDoc(fileRef, { name });
};

export const updateFileAccess = async (fileId: string, accessBy: UserId[]) => {
  const updateFileFn = httpsCallable(F.functions, 'file-updateFile');
  await updateFileFn({ fileId, accessBy });
};

export const updateFileStatus = async (companyId: string, fileId: string, status: string) => {
  const fileRef = getFileDocument(companyId, fileId);
  await updateDoc(fileRef, { status } as Partial<VegaFile>);
};

export const exportFiles = async (companyId: string) => {
  const getAnalytics = httpsCallable(F.functions, 'analytics-exportFiles');
  const result = await getAnalytics({ companyId });
  return result;
};

export const shareFile = async (files: { id: string; access: string }[], emails: string[], vendorUrl?: string) => {
  const shareFileFn = httpsCallable(F.functions, 'file-shareFile');
  const result = await shareFileFn({ files, emails, vendorUrl: vendorUrl ? vendorUrl : ''});
  return result;
};

export const saveFileAsNew = async ({
  companyId,
  parentId = '~',
  name,
  type,
  accessBy,
  blob,
  mode,
  createdBy,
}: SaveFileAsNewParams): Promise<string> => {
  const timeAt = Date.now();
  // Create the initial file document with all required fields
  const fileRef = await addDoc(getFileCollection(companyId), {
    name,
    type,
    parent: parentId,
    accessBy,
    createdAt: timeAt,
    updatedAt: timeAt,
    mode: mode,
    deletedAt: null,
    deletedBy: null,
    companyId: companyId,
    createdBy: createdBy,
  });

  // Upload to storage
  const storagePath = `files/${companyId}/${fileRef.id}.${type}`;
  const storageRef = sRef(F.storage, storagePath);
  await uploadBytes(storageRef, blob);

  const downloadUrl = await getDownloadURL(storageRef);

  await updateDoc(fileRef, {
    id: fileRef.id,
    storagePath,
    downloadUrl,
    status: 'draft',
  });

  return fileRef.id;
};

export const shareFileInternally = async (fileName: string, fileId: string, recipients: string[], status:string) => {
  const shareFileInternallyFn = httpsCallable(F.functions, 'file-shareFileInternally');
  await shareFileInternallyFn({ fileName, fileId,recipients, status });
};

export const canUserOnlyTraverse = (file: VegaFile, userId: string) => {
  return file.type === 'folder' && file.accessBy?.length > 0 && file.traverseBy?.includes(userId);
};
