import { JsonProperty } from '.';
import * as React from 'react';
import { cn } from '@/lib/utils';
import {
  Input as BaseInput,
  RadioGroup as BaseRadioGroup,
  Textarea as BaseTextarea,
  ContentEditable,
} from '../../../../components/ui';
import { Select as VegaSelect } from '@/components/ui/select';
import type {
  SelectProps as BaseSelectProps,
  ContentEditableProps,
  TextAreaProps,
} from '../../../../components/ui';
import { renderTemplate } from '@/lib/templater';
import { DatePicker } from '@/components/ui/date-picker';

type PrimitiveProperty = {
  title?: string;
  description?: string;
  error?: string;
};

export const PropertyTitle: React.FC<{
  property: JsonProperty;
  context?: Record<string, any>;
}> = ({ property, context = {} }) => {
  const title = renderTemplate(property.title ?? '', { self: context });

  return (
    <header>
      {property.title && <label className='text-sm font-semibold'>{title}</label>}
      {property.description && <p className='text-muted-foreground'>{property.description}</p>}
    </header>
  );
};
export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    PrimitiveProperty {}

const PropertyWrapper: React.FC<
  React.PropsWithChildren & {
    title?: string;
    description?: string;
    id?: string;
    error?: string;
    onLabelClick?: () => void;
  }
> = ({ id, title, description, error, children, onLabelClick }) => {
  if (!title && !error) {
    return <>{children}</>;
  }
  return (
    <div className='flex flex-col gap-1' data-error={error ? 'true' : 'false'}>
      <div className='flex flex-col'>
        {title && (
          <label
            htmlFor={id}
            className='text-sm font-medium text-foreground'
            onClick={onLabelClick}
          >
            {title}
          </label>
        )}
        {description && (
          <label htmlFor={id} className='text-xs font-normal text-muted-foreground'>
            {description}
          </label>
        )}
      </div>
      {error && (
        <label htmlFor={id} className='text-xs font-medium text-[tomato] mb-px'>
          {error}
        </label>
      )}
      <div className='flex flex-col gap-1'>{children}</div>
    </div>
  );
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ type = 'text', title, error, description, ...props }, ref) => {
    const id = React.useId();

    return (
      <PropertyWrapper title={title} id={id} error={error} description={description}>
        <BaseInput {...props} id={id} type={type} ref={ref} />
      </PropertyWrapper>
    );
  },
);

Input.displayName = 'FormInput';

type TextareaProps = TextAreaProps &
  PrimitiveProperty & {
    onValueChange?: (value: string) => void;
  };

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ title, description, error, ...props }, ref) => {
    const id = React.useId();

    const handleChange = React.useCallback(
      (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        props.onValueChange?.(event.target.value);
        props.onChange?.(event);
      },
      [props],
    );

    return (
      <PropertyWrapper title={title} id={id} error={error} description={description}>
        <BaseTextarea {...props} id={id} ref={ref} onChange={handleChange} />
      </PropertyWrapper>
    );
  },
);

Textarea.displayName = 'FormTextarea';

type TextareaProps2 = ContentEditableProps & PrimitiveProperty;

const Textarea2 = React.forwardRef<HTMLDivElement, TextareaProps2>(
  ({ title, description, error, ...props }, ref) => {
    const id = React.useId();

    return (
      <PropertyWrapper title={title} id={id} error={error} description={description}>
        <ContentEditable {...props} id={id} ref={ref} />
      </PropertyWrapper>
    );
  },
);

Textarea2.displayName = 'FormTextarea2';

type SelectProps = BaseSelectProps<string> & PrimitiveProperty;

const Select: React.FC<SelectProps> = ({
  className,
  title,
  label,
  error,
  description,
  ...props
}) => {
  const id = React.useId();

  return (
    <PropertyWrapper title={title ?? label} id={id} error={error} description={description}>
      <VegaSelect value={props.value} onValueChange={props.onValueChange}>
        <VegaSelect.Trigger className={cn(className, 'w-full')}>
          <VegaSelect.Value placeholder='Select' />
        </VegaSelect.Trigger>
        <VegaSelect.Content>
          {props.options.map((option, index) => {
            if (option === 'separator') {
              return <VegaSelect.Separator key={`separator-${index}`} />;
            }

            if ('options' in option) {
              return (
                <VegaSelect.Group key={option.label}>
                  <VegaSelect.Label>{option.label}</VegaSelect.Label>
                  {option.options.map((opt) => (
                    <VegaSelect.Item key={opt.value} value={opt.value}>
                      {opt.label}
                    </VegaSelect.Item>
                  ))}
                </VegaSelect.Group>
              );
            }

            return (
              <VegaSelect.Item key={option.value} value={option.value}>
                {option.label}
              </VegaSelect.Item>
            );
          })}
        </VegaSelect.Content>
      </VegaSelect>
    </PropertyWrapper>
  );
};

type CalendarProps = PrimitiveProperty & {
  value: Date | null;
  onValueChange: (value: Date) => void;
};

const Calendar: React.FC<CalendarProps> = ({ title, error, description, ...props }) => {
  const id = React.useId();
  return (
    <PropertyWrapper id={id} title={title} error={error} description={description}>
      <DatePicker id={id} value={props.value} onValueChange={props.onValueChange} />
    </PropertyWrapper>
  );
};

export {
  Input,
  Textarea,
  Textarea2,
  Select,
  BaseRadioGroup as RadioGroup,
  Calendar,
  PropertyWrapper,
};
