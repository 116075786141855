import { Fallback } from '@/components/fallback';
import { createContext } from '@/lib/context';
import type { User } from '@/lib/definitions';
import { ref } from 'firebase/database';
import { Navigate, useLocation } from 'react-router-dom';
import { useDatabase, useDatabaseObjectData, useUser } from 'reactfire';

type UserProvider = {
  user: User;
};

const [useProvider, Provider] = createContext<UserProvider>('RequireUser');

interface RequireUserProps extends React.PropsWithChildren {
  // if the provider should allow user to be null
  isOptional?: boolean;
}

export const RequireUser = ({ children, isOptional }: RequireUserProps) => {
  const { data: auth } = useUser();
  const { pathname } = useLocation();
  const db = useDatabase();
  const { status, data: user } = useDatabaseObjectData<User>(ref(db, `users/${auth?.uid}`), {
    // @ts-expect-error this is a hack to prevent crash when a user is logged out and has just logged in
    idField: `id-${auth?.stsTokenManager.expirationTime}`,
  });

  if (status === 'loading') {
    return <Fallback />;
  }

  // if (status === 'error') {
  //   return <ErrorScreen />;
  // }

  if (!user && !isOptional) {
    return <Navigate to='/login' state={{ from: pathname }} replace />;
  }

  return <Provider user={user}>{children}</Provider>;
};

export { useProvider as useUser };
