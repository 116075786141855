import { JsonArray, JsonPage, JsonMap, JsonPropertyType, JsonPropertyTypeString } from './types';
import { renderTemplate } from '@/lib/templater';

const placeholder = {
  array: [],
  string: '',
  number: 0,
  boolean: false,
};

export const getPlaceholder = (type: JsonPropertyTypeString, defaultValue?: any) => {
  if (defaultValue !== undefined) {
    return defaultValue;
  }
  return placeholder[type as keyof typeof placeholder] ?? {};
};
export const expandExpression = (expression: string, data: Record<string, any>) => {
  return expression.split('.').reduce((acc, key) => (acc ? acc[key] : undefined), data);
};

export const recursiveExpandTemplate = (value: any, data: Record<string, any>): any => {
  // if value is a template expression i.e ${<expression>}
  if (typeof value === 'string' && value.trim().startsWith('${')) {
    const match = value.trim().match(/\${(.*)}/);
    if (match) {
      const [, expression] = match;
      return expandExpression(expression, data);
    }
  }

  if (Array.isArray(value)) {
    return value.map((item: any) => recursiveExpandTemplate(item, data));
  } else if (typeof value === 'object') {
    return Object.entries(value).reduce(
      (acc, [key, value]) => {
        acc[key] = recursiveExpandTemplate(value, data);
        return acc;
      },
      {} as Record<string, any>,
    );
  } else if (typeof value === 'string') {
    return renderTemplate(value, data);
  } else {
    return value;
  }
};

export const pushArray = (property: JsonArray, index: number) => {
  const {
    items: { type },
    defaultItem: defaultValue,
  } = property;
  if (type !== 'object') {
    return getPlaceholder(type, defaultValue);
  }
  return {
    ...getPlaceholder(type, defaultValue),
    __cardinal: getCardinal(index + 1),
    __index: index,
  };
};

export const composeNewArrayValue = (property: JsonArray, context = {}) => {
  const { min = 1, max = Infinity, length, defaultItem, default: defaultValue } = property;
  const offset = property.offset ?? 0;
  if (Array.isArray(defaultValue) && defaultValue.length > 0) {
    if (property.items.type !== 'object') {
      return defaultValue;
    }
    return defaultValue.map((item, index) => {
      return {
        ...getPlaceholder(property.items.type, defaultItem),
        ...recursiveExpandTemplate(item, context),
        __cardinal: getCardinal(offset + index + 1),
        __index: index,
      };
    });
  }

  const decidedLength = length ?? Math.min(Math.max(min, 0), max);
  const value = getPlaceholder(property.items.type, defaultItem);
  return addArraySignature(property, Array(decidedLength).fill(value));
};

export const addArraySignature = <T>(property: JsonArray, data: T[]) => {
  if (property.items.type !== 'object') {
    return data;
  }
  const offset = property.offset ?? 0;
  return data.map((item, index) => ({
    ...item,
    __cardinal: getCardinal(offset + index + 1),
    __index: index,
  })) as (T & { __cardinal: string; __index: number })[];
};

export const isDependenciesSatisfied = (
  dependencies: Record<string, any> = {},
  state: Record<string, any> = {},
) => {
  return Object.entries(dependencies).every(([key, value]) => {
    if (Array.isArray(value)) {
      return value.includes(state[key]);
    }
    return state[key] === value;
  });
};

export const satisfiesRequiredConstraint = (
  data: any,
  schema: JsonPropertyType,
  state: any = {},
): [boolean, any?] => {
  if (schema.required === false) {
    return [true, ''];
  }

  if (schema.dependencies && !isDependenciesSatisfied(schema.dependencies, state)) {
    return [true, ''];
  }

  const message = typeof schema.required === 'string' ? schema.required : 'This field is required';
  const arrayMessage =
    typeof schema.required === 'string' ? schema.required : 'You need to add another Party.';

  if (data === undefined || data === null) {
    return [false, message];
  }

  switch (schema.type) {
    case 'array': {
      if (data.length === 0) {
        return [false, message];
      }
      if (schema.min && data.length < schema.min) {
        return [false, arrayMessage];
      }

      let isValid = true;
      const errors = [] as any[];

      data.forEach((item: any, index: number) => {
        const itemSchema = {
          ...schema.items,
          required: schema.items.required ?? schema.required,
        } as JsonArray;
        const [itemIsValid, itemErrors] = satisfiesRequiredConstraint(item, itemSchema, state);
        if (!itemIsValid) {
          isValid = false;
          errors[index] = itemErrors;
        }
      });

      return [isValid, errors];
    }
    case 'parties': {
      if (data.length === 0) {
        return [false, message];
      }
      if (schema.min && data.length < schema.min) {
        return [false, arrayMessage];
      }

      let isValid = true;
      const errors = [] as any[];

      data.forEach((item: any, index: number) => {
        const itemSchema = {
          ...schema.items,
          required: schema.items.required ?? schema.required,
        } as JsonArray;
        const [itemIsValid, itemErrors] = satisfiesRequiredConstraint(item, itemSchema, state);
        if (!itemIsValid) {
          isValid = false;
          errors[index] = itemErrors;
        }
      });

      return [isValid, errors];
    }  
    case 'object': {
      let objectIsValid = true;
      const objectErrors = {} as Record<string, any>;
      Object.entries(schema.properties).forEach(([key, property]) => {
        const [itemIsValid, itemErrors] = satisfiesRequiredConstraint(data[key], property, data);
        if (!itemIsValid) {
          objectIsValid = false;
          objectErrors[key] = itemErrors;
        }
      });
      return [objectIsValid, objectErrors];
    }
    case 'string':
      if (data === '') {
        return [false, message];
      }
      return [true, ''];
    default:
      return [true, ''];
  }
};

export const isDataValid = (data: any = {}, schema: JsonPage): [boolean, Record<string, any>?] => {
  const error = {} as Record<string, any>;
  const isValid = Object.entries(schema.properties).every(([key, property]) => {
    const value = data[key];
    const [satisfied, message] = satisfiesRequiredConstraint(value, property, data);
    if (!satisfied) {
      error[key] = message;
    }
    return satisfied;
  });

  return [isValid, error];
};

export const isDefined = (value: any) => {
  return value !== undefined && value !== null;
};

const cleanDataImpl = (data: any, schema: JsonPropertyType, state: any = {}): any => {
  if (!isDefined(data)) {
    return undefined;
  }
  if (schema.dependencies && !isDependenciesSatisfied(schema.dependencies, state)) {
    return undefined;
  }
  switch (schema.type) {
    case 'array':
      case 'parties': 
    case 'map': {
      const value = (data as [])?.map((item: any) => cleanDataImpl(item, schema.items, state));
      if (value && value.every(Boolean)) {
        return value;
      }
      return undefined;
    }
    case 'object': {
      const result = Object.entries(schema.properties).reduce(
        (acc, [key, property]) => {
          const value = cleanDataImpl(data[key], property, data);
          if (value) {
            acc[key] = value;
          }
          return acc;
        },
        {} as Record<string, any>,
      );
      // store all keys starting with __
      Object.keys(data).forEach((key) => {
        if (key.startsWith('__')) {
          result[key] = data[key];
        }
      });
      return result;
    }
    case 'string':
      if (data === '') {
        return undefined;
      }
      return data.trim();
    default:
      return data;
  }
};

export const cleanData = (data: any = {}, schema: JsonPage): any => {
  return Object.entries(schema.properties).reduce(
    (acc, [key, property]) => {
      const value = cleanDataImpl(data[key], property, data);
      if (isDefined(value)) {
        acc[key] = value;
      }
      return acc;
    },
    {} as Record<string, any>,
  );
};

export const dateToString = (date: Date) => {
  return date.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
};

export function getCardinal(number: number): string {
  if (number < 1 || number > 100) {
    return '';
  }

  const units: string[] = [
    '',
    'first',
    'second',
    'third',
    'fourth',
    'fifth',
    'sixth',
    'seventh',
    'eighth',
    'ninth',
  ];
  const teens: string[] = [
    'tenth',
    'eleventh',
    'twelfth',
    'thirteenth',
    'fourteenth',
    'fifteenth',
    'sixteenth',
    'seventeenth',
    'eighteenth',
    'nineteenth',
  ];
  const tens: string[] = [
    '',
    '',
    'twentieth',
    'thirtieth',
    'fortieth',
    'fiftieth',
    'sixtieth',
    'seventieth',
    'eightieth',
    'ninetieth',
  ];

  if (number < 10) {
    return units[number];
  } else if (number < 20) {
    return teens[number - 10];
  } else {
    const unitDigit = number % 10;
    const tensDigit = Math.floor(number / 10);
    return tens[tensDigit] + (unitDigit !== 0 ? ' ' + units[unitDigit] : '');
  }
}
export const getMapFromObject = (property: JsonMap, state = {}) => {
  const result = expandExpression(property.from, state);

  if (Array.isArray(result)) {
    return result;
  }
  return [];
};
export const composeNewMapValue = (property: JsonMap, state: any, context = {}) => {
  const { defaultItem } = property;
  const from = getMapFromObject(property, state) ?? [];
  const value = getPlaceholder(property.items.type, defaultItem);

  if (property.items.type !== 'object') {
    return Array(from.length).fill(value);
  }

  const fromProperties = Object.entries(property.fromProperties ?? {});

  return Array(from.length)
    .fill(value)
    .map((item, index) => {
      const fromFields = fromProperties.reduce(
        (acc, [key, fromKey]) => {
          acc[key] = from[index][fromKey];
          return acc;
        },
        {} as Record<string, any>,
      );
      return {
        ...fromFields,
        ...recursiveExpandTemplate(item, context),
        __cardinal: getCardinal(index + 1),
        __index: index,
      };
    });
};
export const persistFromProperties = (property: JsonMap, value: any, state: any) => {
  if (property.items.type !== 'object') return value;
  const from = getMapFromObject(property, state);
  const fromProperties = Object.entries(property.fromProperties ?? {});
  return value.map((item: any, index: number) => {
    const fromFields = fromProperties.reduce(
      (acc, [key, fromKey]) => {
        acc[key] = from[index][fromKey];
        return acc;
      },
      {} as Record<string, any>,
    );
    return {
      ...item,
      ...fromFields,
      __cardinal: getCardinal(index + 1),
      __index: index,
    };
  });
};
