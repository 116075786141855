import { useMemo } from 'react';
import { useCompany } from '../providers/company';

export const useMember = (consumerName: string, id: string) => {
  const { activeCompany: company } = useCompany(consumerName);

  return useMemo(() => {
    if (id in company.members) {
      return company.members[id];
    }

    if (company.removedMembers && id in company.removedMembers) {
      return company.removedMembers[id];
    }

    return null;
  }, [company, id]);
};

export const useMembers = (consumerName: string) => {
  const { activeCompany: company } = useCompany(consumerName);

  const getMember = (id: string, checkRemoved = true) => {
    if (id in company.members) {
      return company.members[id];
    }

    if (checkRemoved && company.removedMembers && id in company.removedMembers) {
      return company.removedMembers[id];
    }

    return null;
  };

  const members = useMemo(() => {
    return Object.values(company.members).sort((a, b) => a.name.localeCompare(b.name));
  }, [company]);

  const removedMembers = useMemo(() => {
    return company.removedMembers ? Object.values(company.removedMembers) : [];
  }, [company]);

  return { members, removedMembers, getMember };
};
