import { createContext } from '@/lib/context';
import { cn } from '@/lib/utils';
import { forwardRef } from 'react';

type TableProviderValue = {
  noHover?: boolean;
};

const [useTable, TableProvider] = createContext<TableProviderValue>('Table.Root');

const Table = forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement> & TableProviderValue
>(({ className, noHover, ...props }, ref) => (
  <TableProvider noHover={noHover}>
    <div className='relative w-full overflow-auto whitespace-nowrap'>
      <table
        ref={ref}
        className={cn('w-full caption-bottom text-sm divide-y divide-border', className)}
        {...props}
      />
    </div>
  </TableProvider>
));
Table.displayName = 'Table';

const TableHeader = forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => <thead ref={ref} className={cn('', className)} {...props} />);
TableHeader.displayName = 'TableHeader';

const TableBody = forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => <tbody ref={ref} className={cn('', className)} {...props} />);
TableBody.displayName = 'TableBody';

const TableFooter = forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot ref={ref} className={cn('bg-muted/50 font-medium=', className)} {...props} />
));
TableFooter.displayName = 'TableFooter';

const TableRow = forwardRef<HTMLTableRowElement, React.HTMLAttributes<HTMLTableRowElement>>(
  ({ className, ...props }, ref) => {
    const { noHover } = useTable('TableRow');
    return (
      <tr
        ref={ref}
        className={cn(
          'transition-colors even:bg-muted/70 odd:bg-background data-[state=selected]:bg-primary/[0.1]',
          {
            'hover:bg-primary/[0.07]': !noHover,
          },
          className,
        )}
        {...props}
      />
    );
  },
);
TableRow.displayName = 'TableRow';

const TableHead = forwardRef<HTMLTableCellElement, React.ThHTMLAttributes<HTMLTableCellElement>>(
  ({ className, ...props }, ref) => (
    <th
      ref={ref}
      className={cn(
        'h-12 px-5 text-left align-middle font-medium bg-background hover:bg-background text-muted-foreground [&:has([role=checkbox])]:pr-0',
        className,
      )}
      {...props}
    />
  ),
);
TableHead.displayName = 'TableHead';

const TableCell = forwardRef<HTMLTableCellElement, React.TdHTMLAttributes<HTMLTableCellElement>>(
  ({ className, ...props }, ref) => (
    <td
      ref={ref}
      className={cn(
        'py-2.5 px-5 align-middle [&:has([role=checkbox])]:pr-0 whitespace-nowrap',
        className,
      )}
      {...props}
    />
  ),
);
TableCell.displayName = 'TableCell';

const TableCaption = forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption ref={ref} className={cn('mt-4 text-sm text-muted-foreground', className)} {...props} />
));
TableCaption.displayName = 'TableCaption';

export { Table, TableHeader, TableBody, TableFooter, TableRow, TableHead, TableCell, TableCaption };
