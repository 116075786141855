import { Button, Input, Menu, Modal, RadioGroup } from '@/components/ui';
import { VegaFile } from '@/lib/definitions';
import { cn } from '@/lib/utils';
import { Link } from 'react-router-dom';
import { ChevronDownIcon, PlusIcon } from '@radix-ui/react-icons';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Spinner } from '../../../../../components/icons';
import { useControllableState, usePromise } from '@/lib/hooks';
import { FolderIcon, SquareMousePointerIcon } from 'lucide-react';
import { FileAvatar } from './file-avatar';
import { createFolder } from '@/features/company/actions/files';
import { useFile, useFiles } from '@/features/company/hooks/use-files';
import { FileBreadcrumb } from './file-breadcrumb';
import { Breadcrumb } from '@/components/ui/breadcrumb';
import { useCompany } from '@/features/company/providers/company';

type SelectFileModalProps = {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  children?: React.ReactNode;
  initialParent?: string;
  onFileChange?: (file: VegaFile | null) => void;
  title?: string;
  description?: string;
  mode?: VegaFile['type'];
  addFolder?: boolean;
  preselectedFile?: any;
  folderSelect?:boolean
};

type FileItemProps = {
  file: VegaFile | '~';
  onSelect: () => void;
  parent: boolean;
  disabled: boolean;
};

export const SelectFileModal = (props: SelectFileModalProps) => {
  const [open, onOpenChange] = useControllableState({
    prop: props.open,
    onChange: props.onOpenChange,
    defaultProp: false,
  });

  // Handle the conversion of fileId to id for preSelectedFile
  const preselectedFile = props.preselectedFile
    ? { ...props.preselectedFile, id: props.preselectedFile.fileId || props.preselectedFile.id }
    : null;

  const [selectedFile, setSelectedFile] = useState<VegaFile | null>(preselectedFile ?? null);

  const { activeCompany: company } = useCompany('VegaFileSelector');
  const isFreePlan = company.plan?.type === 'free';

  const [parentId, setParentId] = useState(props.initialParent ?? '~');

  const { data: files, status } = useFiles('SelectFileModal', parentId);
  const { data: parent, hasEmitted } = useFile('SelectFileModal', parentId);

  useEffect(() => {
    setParentId(props.initialParent ?? '~');
  }, [props.initialParent]);

  useEffect(() => {
    if (parent && !hasEmitted) {
      setSelectedFile(parent);
    }
  }, [parent, hasEmitted]);

  const handleValueChange = (value: string) => {
    setSelectedFile(files?.find((file) => file.id === value) ?? null);
  };

  return (
    <Modal.Root open={open} onOpenChange={onOpenChange}>
      {props.children && <Modal.Trigger asChild>{props.children}</Modal.Trigger>}
      <Modal.Content>
        <Modal.Header>
          <Modal.Title className='flex gap-4 items-center'>
            <SquareMousePointerIcon width={24} height={24} className='text-primary' />
            <span>Select {props.mode ?? 'folder'}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='space-y-2'>
          <div className='flex justify-between items-center'>
            <FileBreadcrumb
              fileId={parentId}
              component={({ file }) => (
                <span className='cursor-pointer underline' onClick={() => setParentId(file.id)}>
                  {file.name}
                </span>
              )}
            >
              <Breadcrumb.Item>
                <Breadcrumb.Link asChild>
                  <span onClick={() => setParentId('~')} className='cursor-pointer'>
                    Root
                  </span>
                </Breadcrumb.Link>
              </Breadcrumb.Item>
            </FileBreadcrumb>

            {props.addFolder === true && !isFreePlan && (
              <NewFolderImpl parentId={parentId}>
                <Button variant='mid' className='gap-2'>
                  <PlusIcon /> Add folder
                </Button>
              </NewFolderImpl>
            )}
          </div>
          <div className='space-y-1'>
            {status === 'loading' ? (
              <div className='grid place-items-center h-48'>
                <Spinner />
              </div>
            ) : isFreePlan ? (
              <div className=' h-32'>
                <p className='text-muted-foreground/90'>
                  You are currently on the free plan. Upgrade to use this feature.
                </p>
                <Button size='lg' className='mt-4' asChild>
                  <Link to='/payment'>Upgrade account</Link>
                </Button>
              </div>
            ) : files?.length === 0 ? (
              <p className='text-muted-foreground/90 h-32'>
                {parent?.id === '~' ? 'You have no files yet.' : 'This folder is empty.'}
              </p>
            ) : (
              <RadioGroup.Root
              value={selectedFile?.id ?? '~'}
              defaultValue={parentId}
              onValueChange={handleValueChange}
              className="space-y-1"
            >
              {parentId === '~' && (
                <FileItem file="~" onSelect={() => setParentId('~')} parent disabled={false} />
              )}
              {files
                ?.filter((file) => !(props.folderSelect && props.mode !== file.type)) 
                .map((file) => (
                  <FileItem
                    file={file}
                    onSelect={() => {
                      if (props.mode === file.type) {
                        setSelectedFile(file);
                      }
                      if (file.type === 'folder') {
                        setParentId(file.id);
                      }
                    }}
                    parent={false}
                    disabled={props?.mode !== file.type}
                    key={file.id}
                  />
                ))}
            </RadioGroup.Root>
            
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Close asChild>
            <Button size='lg' variant='outline'>
              Cancel
            </Button>
          </Modal.Close>
          <Button
            size='lg'
            type='submit'
            loading={false}
            onClick={() => {
              props.onFileChange?.(selectedFile);
              onOpenChange(false);
            }}
          >
            {selectedFile?.id === parentId ? 'Save here' : 'Choose'}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};

const FileItem = ({ file, onSelect, parent, disabled }: FileItemProps) => {
  const icon = useMemo(() => {
    if (file === '~') {
      return <FolderIcon className='w-4 h-4' />;
    }
    return <FileAvatar file={file} />;
  }, [file]);

  return (
    <div
      className={cn('h-12 hover:bg-muted rounded-lg cursor-pointer flex gap-6 items-center p-3', {
        'bg-secondary/20 hover:bg-secondary/30': parent,
      })}
    >
      <label
        htmlFor={file === '~' ? '~' : file.id}
        className='cursor-pointer flex gap-6 items-center grow'
        onClick={() => {
          if (!disabled && file !== '~' && file.type !== 'folder') {
            onSelect();
          }
        }}
      >
        <span className='text-muted-foreground/90'>{icon}</span>
        <div className='flex gap-2 items-center text-sm'>
          {file === '~' ? (
            'Choose root folder'
          ) : (
            <span
              onClick={(e) => {
                e.stopPropagation();
                onSelect();
              }}
              className='flex gap-2 items-center hover:bg-secondary/20'
            >
              {file.name}
              {file.type === 'folder' && (
                <ChevronDownIcon
                  className='-rotate-90 shrink-0 cursor-pointer'
                  width={20}
                  height={20}
                />
              )}
            </span>
          )}
        </div>
      </label>
      <div>
        {!disabled && (
          <RadioGroup.Radio
            id={file === '~' ? '~' : file.id}
            value={file === '~' ? '~' : file.id}
            className='shrink-0'
          />
        )}
      </div>
    </div>
  );
};

const NewFolderImpl = ({ parentId, children }: { parentId: string; children: React.ReactNode }) => {
  const [open, onOpenChange] = useState(false);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    setFocus,
  } = useForm<{ name: string }>();

  useEffect(() => {
    if (open) {
      setFocus('name');
    }
  }, [open]);

  const [makeFolder, isCreating] = usePromise(
    handleSubmit(async ({ name }) => {
      await createFolder(name, parentId);
      reset();
      onOpenChange(false);
    }),
  );

  return (
    <Menu.Root open={open} onOpenChange={onOpenChange}>
      <Menu.Trigger asChild>{children}</Menu.Trigger>
      <Menu.Content className='bg-background rounded-md min-w-fit w-[300px] border p-2 z-50'>
        <form onSubmit={makeFolder} className='space-y-4 relative p-4'>
          <h4 className='font-medium'>Create folder here</h4>
          <Input
            label='Folder Name'
            placeholder='Enter folder name'
            className='mx-1'
            {...register('name', {
              required: 'This field is required',
            })}
            error={errors.name?.message}
          />
          <div className='pt-4 flex justify-end space-x-2'>
            <Button type='submit' loading={isCreating}>
              Create
            </Button>
          </div>
        </form>
      </Menu.Content>
    </Menu.Root>
  );
};
