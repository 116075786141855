import { useControllableState } from '@/lib/hooks/state';
import { cn } from '@/lib/utils';
import React, { useImperativeHandle, useState } from 'react';

const Wrapper: React.FC<
  React.PropsWithChildren & { label?: string; id?: string; error?: string }
> = ({ id, label, error, children }) => {
  if (!label && !error) {
    return <>{children}</>;
  }
  return (
    <div className='flex flex-col gap-1'>
      {label && (
        <label htmlFor={id} className='text-sm font-normal text-muted-foreground'>
          {label} (press &apos;Enter&apos; to add each one)
        </label>
      )}
      <div className='flex flex-col gap-1'>
        {children}
        {error && <small className='text-sm font-normal text-[tomato]'>{error}</small>}
      </div>
    </div>
  );
};

export type TagInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'value'
> & {
  label?: string;
  error?: string;
  onChange?: (tags: string[]) => void;
  onValidate?: (tag: string) => boolean;
  value?: string[];
};

type TagInputComponent = {
  value: string[];
  focus?: () => void;
  blur?: () => void;
};

export const TagInput = React.forwardRef<TagInputComponent, TagInputProps>(
  (
    { className, label, error, disabled, value, onChange, onFocus, onBlur, onValidate, ...props },
    forwardedRef,
  ) => {
    const id = React.useId();
    const [tags, setTags] = useControllableState<string[]>({
      prop: value,
      defaultProp: [],
      onChange,
    });
    const [focus, setFocus] = useState(false);
    const ref = React.useRef<HTMLInputElement>(null);

    useImperativeHandle(forwardedRef, () => {
      return {
        value: tags ?? [],
        focus: () => ref.current?.focus(),
        blur: () => ref.current?.blur(),
      };
    });

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      event.stopPropagation(); // to allow pasting in the file page next to the VegaWebViewer
      if (event.key === 'Enter' || event.key === ',') {
        if (event.shiftKey) return;
        event.preventDefault();
        const newTag = event.currentTarget.value.trim();
        if (newTag && tags && !tags.includes(newTag)) {
          if (onValidate && !onValidate(newTag)) {
            return;
          }
          setTags([...tags, newTag]);
          event.currentTarget.value = '';
        }
      } else if (event.key === 'Backspace' && !event.currentTarget.value && tags?.length) {
        setTags(tags.slice(0, -1));
      }
    };

    const handleRemoveTag = (tagToRemove: string) => {
      setTags(tags?.filter((tag) => tag !== tagToRemove));
    };

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      setFocus(true);
      onFocus?.(event);
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      const inputValue = event.currentTarget.value.trim();
      if (inputValue && tags && !tags.includes(inputValue)) {
        if (onValidate && !onValidate(inputValue)) {
          onBlur?.(event);
          return;
        }
        setTags([...tags, inputValue]);
        event.currentTarget.value = '';
      }
      setFocus(false);
      onBlur?.(event);
    };

    return (
      <Wrapper label={label!} id={id} error={error}>
        <div
          className={cn(
            'flex gap-2 flex-wrap items-center min-h-[42px] rounded-xl border border-input bg-transparent px-3 py-1 text-sm shadow-sm ring-offset-background cursor-text',
            {
              'outline-none ring-2 ring-ring ring-offset-1': focus,
              'disabled:cursor-not-allowed disabled:opacity-50': disabled,
            },
            className,
          )}
          onClick={() => ref.current?.focus()}
        >
          {tags?.map((tag) => (
            <div
              key={tag}
              className='bg-secondary/50 rounded-lg px-3 py-1 text-sm font-medium flex items-center cursor-pointer'
            >
              {tag}
              <button
                type='button'
                onClick={() => handleRemoveTag(tag)}
                className='ml-2'
                aria-label={`Remove ${tag}`}
              >
                <svg
                  className='h-3 w-3 fill-current text-gray-600'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M10 8.586L6.707 5.293a1 1 0 00-1.414 1.414L8.586 10l-3.293 3.293a1 1 0 101.414 1.414L10 11.414l3.293 3.293a1 1 0 001.414-1.414L11.414 10l3.293-3.293a1 1 0 00-1.414-1.414L10 8.586z' />
                </svg>
              </button>
            </div>
          ))}
          <input
            type='text'
            id={id}
            onKeyDown={handleKeyDown}
            ref={ref}
            {...props}
            className={cn(
              'flex-1 border-none outline-none bg-transparent p-0 placeholder:text-muted-foreground/50',
              !tags?.length && 'px-3',
            )}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </div>
      </Wrapper>
    );
  },
);
