import { Status } from '@/lib/definitions';

export const defaultStatuses = {
  draft: {
    label: 'draft',
    color: '#497991',
  },
  pending: {
    label: 'pending',
    color: '#FFB800',
  },
  signed: {
    label: 'signed',
    color: '#a0c3ff',
  },
  declined: {
    label: 'declined',
    color: '#ff6b78',
  },
  completed: {
    label: 'completed',
    color: '#64C2A6',
  },
  sent_approval: {
    label: 'sent for approval',
    color: '#2190d6',
  },
  approved: {
    label: 'approved',
    color: '#619e36',
  },
  failed_approval: {
    label: 'Failed Approval',
    color: '#fc4b78',
  },
} as const;

export const defaultStatusesArray = Object.entries(defaultStatuses).map(([id, status]) => ({
  id,
  ...status,
})) as Status[];
