import React from 'react';
import { getDatabase, push, ref, set } from 'firebase/database';
import { Input, Button, Modal } from '@/components/ui';
import { app } from '@/lib/firebase';
import { useForm } from 'react-hook-form';
import { cn } from '@/lib/utils';
import { useControllableState, usePromise } from '@/lib/hooks';
import { Status } from '@/lib/definitions';
import { useStatuses } from '../../hooks/use-statuses';
import { useToast } from '@/lib/hooks/use-toast';
import { useCompany } from '../../providers/company';

interface CustomStatusModalProps {
  children?: React.ReactNode;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  onAdd?: (status: string) => void;
}

const defaultColors = [
  { label: 'gray', color: '#6b7280' },
  { label: 'zinc', color: '#71717a' },
  { label: 'stone', color: '#78716c' },
  { label: 'red', color: '#ef4444' },
  { label: 'orange', color: '#f97316' },
  { label: 'amber', color: '#f59e0b' },
  { label: 'yellow', color: '#eab308' },
  { label: 'lime', color: '#84cc16' },
  { label: 'green', color: '#22c55e' },
  { label: 'teal', color: '#14b8a6' },
  { label: 'sky', color: '#0ea5e9' },
  { label: 'blue', color: '#3b82f6' },
  { label: 'indigo', color: '#6366f1' },
  { label: 'purple', color: '#a855f7' },
  { label: 'fuchsia', color: '#d946ef' },
  { label: 'pink', color: '#ec4899' },
] as Status[];

export const CustomStatusModal = (props: CustomStatusModalProps) => {
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<Status & { name: string }>({
    mode: 'onChange',
  });

  const db = getDatabase(app);
  const [open, onOpenChange] = useControllableState({
    prop: props.open,
    onChange: props.onOpenChange,
    defaultProp: false,
  });

  const { activeCompany: company } = useCompany('CustomStatusModal');
  const { statuses } = useStatuses('CustomStatusModal');
  const { toast } = useToast();

  const label = watch('label');

  const [createStatus, isCreating] = usePromise(
    handleSubmit(async (values) => {
      const statusRef = await push(ref(db, `companies/${company?.id}/statuses`));
      if (!statusRef.key) throw new Error('Failed to create status');
      await set(statusRef, {
        id: statusRef.key,
        label: values.name.toLowerCase(),
        color: values.color,
      });
      reset();
      props.onAdd?.(statusRef.key);
      toast({
        description: 'The custom status has been added successfully.',
      });
      onOpenChange(false);
    }),
  );

  return (
    <Modal.Root open={open} onOpenChange={onOpenChange}>
      {props.children && <Modal.Trigger asChild>{props.children}</Modal.Trigger>}
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Add New Status</Modal.Title>
        </Modal.Header>
        <form onSubmit={createStatus}>
          <Modal.Body className='space-y-4'>
            <Input
              label='New Status'
              placeholder='Enter status name'
              className='mx-1'
              {...register('name', {
                required: 'This field is required',
                validate: (value) => {
                  // prevent duplicate status
                  const exists = statuses.some((status) => status.label === value.toLowerCase());
                  if (exists) return 'Status already exists';
                  return true;
                },
              })}
              error={errors.name?.message}
            />
            <section className='space-y-2'>
              <div className='text-sm font-normal text-muted-foreground'>Select a Color</div>
              <div className='grid grid-cols-[repeat(auto-fill,minmax(3.5rem,1fr))] gap-1'>
                {defaultColors.map((status) => (
                  <button
                    key={status.label}
                    type='button'
                    className={cn(
                      'h-10 rounded-md cursor-pointer relative ring-2 ring-transparent ring-offset-1',
                      'text-transparent hover:text-black transition-colors duration-200',
                      {
                        'ring-2 ring-black': label === status.label,
                      },
                    )}
                    style={{ backgroundColor: status.color }}
                    onClick={() => {
                      setValue('color', status.color);
                      setValue('label', status.label);
                    }}
                  >
                    <span className='absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center text-xs font-semibold'>
                      {status.label}
                    </span>
                  </button>
                ))}
              </div>
            </section>
          </Modal.Body>
          <Modal.Footer>
            <Modal.Close asChild>
              <Button size='lg' variant='outline'>
                Cancel
              </Button>
            </Modal.Close>
            <Button size='lg' type='submit' loading={isCreating}>
              Add
            </Button>
          </Modal.Footer>
        </form>
      </Modal.Content>
    </Modal.Root>
  );
};
