import { Permission } from '@/data/permission';
import { useUser } from '@/providers/user';
import { Fragment, useMemo } from 'react';
import { usePermissions } from '../hooks/use-roles';
import { hasPermission } from '../lib/permission';

type ACLProps = {
  permission: Permission | Permission[];
  children: React.ReactNode;
  fallback?: React.ReactNode;
};

export const ACL = ({ permission, children, fallback }: ACLProps) => {
  const { user } = useUser('ACL');

  const requiredPermissions = useMemo(() => {
    return Array.isArray(permission) ? permission : [permission];
  }, [permission]);

  const permissions = usePermissions('ACL', user.uid);

  const canAccess = useMemo(() => {
    return hasPermission(permissions, requiredPermissions);
  }, [permissions, requiredPermissions]);

  return <Fragment>{canAccess ? children : (fallback ?? null)}</Fragment>;
};
