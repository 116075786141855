import { HandWaveIcon } from '@/components/icons';
import { Button, Input, Textarea } from '@/components/ui';
import { useUser } from '@/providers/user';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAuth, useFunctions } from 'reactfire';
import { httpsCallable } from 'firebase/functions';
import { useEffect } from 'react';
import { updateProfile } from 'firebase/auth';
import { usePromise } from '@/lib/hooks';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { isPEMFormat } from '../lib/utils';

const VendorSchema = z.object({
  name: z.string().min(1, { message: 'Your company name is required' }),
  primaryColor: z
    .string()
    .regex(
      /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\s+){2}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
      {
        message: 'Please enter valid RGB values (e.g., 255 255 255)',
      },
    ),
  logoUrl: z
    .string()
    .url({ message: 'Please enter a valid URL' })
    .refine((url) => url.startsWith('https://'), { message: 'URL must use HTTPS protocol' }),
  publicKey: z.string().min(1, { message: 'Public key is required' }).refine(isPEMFormat, {
    message: 'Invalid public key format. Must be a valid PEM format public key.',
  }),
});

type VendorDTO = z.infer<typeof VendorSchema>;

export const VendorOnboarding = () => {
  const vendorForm = useForm<VendorDTO>({
    resolver: zodResolver(VendorSchema),
  });

  const { user } = useUser('VendorOnboarding');
  const navigate = useNavigate();
  const auth = useAuth();
  const functions = useFunctions();
  const vendorRegisterFn = httpsCallable(functions, 'vendor-register');

  useEffect(() => {
    if (user) {
      vendorForm.setValue('name', user.fullName ?? '');
    }
  }, [user, vendorForm]);

  const handleSubmit = async (data: VendorDTO) => {
    if (auth.currentUser) {
      await updateProfile(auth.currentUser, { displayName: data.name });
      await vendorRegisterFn({
        name: data.name,
        branding: {
          primaryColor: data.primaryColor,
          logoUrl: data.logoUrl,
        },
        publicKey: data.publicKey,
        id: user.uid,
      });
    }
    navigate('/');
  };

  const [submitVendor, isSubmittingVendor] = usePromise(vendorForm.handleSubmit(handleSubmit));

  return (
    <form onSubmit={submitVendor} className='flex flex-col gap-8 mb-10'>
      <header className='space-y-2'>
        <div className='flex gap-6 items-center'>
          <HandWaveIcon size={36} className='text-secondary' />
          <h2 className='text-3xl font-medium tracking-tighter'>
            Hello, Welcome to the Vega Vendors
          </h2>
        </div>
        <p className='text-sm text-muted-foreground'>Tell us a few things about your company</p>
      </header>
      <div className='flex flex-col gap-6'>
        <Input
          placeholder='Jimoh Damijo'
          label='Enter your name'
          {...vendorForm.register('name')}
          error={vendorForm.formState.errors.name?.message}
        />
        <Input
          placeholder='255 255 255'
          label='Enter your primary color (RGB)'
          {...vendorForm.register('primaryColor')}
          error={vendorForm.formState.errors.primaryColor?.message}
        />
        <Input
          placeholder='https://example.com/logo.png'
          label='Enter your logo url'
          {...vendorForm.register('logoUrl')}
          error={vendorForm.formState.errors.logoUrl?.message}
        />
        <Textarea
          placeholder='Enter your public key'
          label='Enter your public key'
          {...vendorForm.register('publicKey')}
          error={vendorForm.formState.errors.publicKey?.message}
        />
        <Button size='lg' type='submit' disabled={isSubmittingVendor} loading={isSubmittingVendor}>
          Submit
        </Button>
      </div>
    </form>
  );
};
