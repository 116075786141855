import { Link } from 'react-router-dom';
import accessDeniedSvg from '@/assets/access-denied.svg';
import { Button } from '@/components/ui';

export const AccessDenied = () => {
  return (
    <section className='flex items-center p-16 justify-center container'>
      <div className='flex flex-col items-center gap-6 max-w-md text-center'>
        <img src={accessDeniedSvg} width={200} />
        <p className='text-xl md:text-2xl'>
          Oops!! Sorry you don&apos;t have access to view this page
        </p>
        <Button asChild variant='link'>
          <Link to='/'>Go back to home</Link>
        </Button>
      </div>
    </section>
  );
};
