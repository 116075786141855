import { Role } from '@/lib/definitions';

export type Permission =
  | 'admin.dashboard:view'
  | 'admin.dashboard:edit'
  | 'admin.dashboard:delete'
  | 'admin.dashboard:publish'
  | 'admin.dashboard:unpublish'
  // task management
  | 'admin.task:*'
  | 'admin.task:view'
  | 'admin.task:edit'
  | 'admin.task:delete'
  | 'admin.task:assign'
  | 'admin.task:create'

  // for team management
  | 'admin.team:*'
  | 'admin.team:view'
  | 'admin.team:edit'
  | 'admin.team:delete'
  | 'admin.team:create'
  // for agreement management
  | 'admin.agreement:*'
  | 'admin.agreement:view'
  | 'admin.agreement:edit'
  | 'admin.agreement:delete'
  | 'admin.agreement:create'

  // for Legal
  | 'admin.legal:*'
  | 'admin.legal:view'

  // for file management
  | 'admin.file:*'
  | 'admin.file:view'
  | 'admin.file:edit'
  | 'admin.file:delete'
  | 'admin.file:create'
  // for file management
  | 'admin.role:*'
  | 'admin.role:view'
  | 'admin.role:edit'
  | 'admin.role:delete'
  | 'admin.role:create'
  // for account management
  | 'admin.account:*'
  | 'admin.account:view'
  | 'admin.account:edit'
  | 'admin.account:delete'
  | 'admin.account:create'
  // for approval management
  | 'admin.approval:*'
  | 'admin.approval:view'
  | 'admin.approval:edit'
  | 'admin.approval:delete'
  | 'admin.approval:create'

  //for clause library
  | 'admin.clause:*'
  | 'admin.clause:view'
  | 'admin.clause:edit'
  | 'admin.clause:delete'
  | 'admin.clause:create'

  // for counterparty management
  | 'admin.counterparty:*'
  | 'admin.counterparty:view'
  | 'admin.counterparty:edit'
  | 'admin.counterparty:delete'
  | 'admin.counterparty:create'

    // for categories management
    | 'admin.categories:*'
    | 'admin.categories:view'
    | 'admin.categories:edit'
    | 'admin.categories:delete'
    | 'admin.categories:create'

  //for custom contract
  | 'admin.custom:*'
  | 'admin.custom:create'
  | 'admin.custom:view'

  // for ticket management
  | 'admin.ticket:*'
  | 'admin.ticket:view'
  | 'admin.ticket:edit'
  | 'admin.ticket:delete'
  | 'admin.ticket:assign'
  | 'admin.ticket:create';

export const permissions = [
  {
    feature: 'Dashboard',
    prefix: 'admin.dashboard',
    permissions: [
      {
        value: 'admin.dashboard:view',
        label: 'View dashboard',
      },
      {
        value: 'admin.dashboard:edit',
        label: 'Edit dashboard',
      },
      {
        value: 'admin.dashboard:delete',
        label: 'Delete dashboard',
      },
      {
        value: 'admin.dashboard:publish',
        label: 'Publish dashboard',
      },
      {
        value: 'admin.dashboard:unpublish',
        label: 'Unpublish dashboard',
      },
    ],
  },
  {
    feature: 'Task management',
    prefix: 'admin.task',
    permissions: [
      {
        value: 'admin.task:view',
        label: 'View task',
      },
      {
        value: 'admin.task:edit',
        label: 'Edit task',
      },
      {
        value: 'admin.task:delete',
        label: 'Delete task',
      },
      {
        value: 'admin.task:create',
        label: 'Create task',
      },
    ],
  },
  {
    feature: 'Team management',
    prefix: 'admin.team',
    permissions: [
      {
        value: 'admin.team:view',
        label: 'View team',
      },
      {
        value: 'admin.team:edit',
        label: 'Edit team',
      },
      {
        value: 'admin.team:delete',
        label: 'Delete team',
      },
      {
        value: 'admin.team:create',
        label: 'Create team',
      },
    ],
  },
  {
    feature: 'Counterparty management',
    prefix: 'admin.counterparty',
    permissions: [
      {
        value: 'admin.counterparty:view',
        label: 'View counterparty',
      },
      {
        value: 'admin.counterparty:edit',
        label: 'Edit counterparty',
      },
      {
        value: 'admin.counterparty:delete',
        label: 'Delete counterparty',
      },
      {
        value: 'admin.counterparty:create',
        label: 'Create counterparty',
      },
    ],
  },
  {
    feature: 'Clause management',
    prefix: 'admin.clause',
    permissions: [
      {
        value: 'admin.clause:view',
        label: 'View clause',
      },
      {
        value: 'admin.clause:edit',
        label: 'Edit clause',
      },
      {
        value: 'admin.clause:delete',
        label: 'Delete clause',
      },
      {
        value: 'admin.clause:create',
        label: 'Create clause',
      },
    ],
  },
  {
    feature: 'Categories management',
    prefix: 'admin.categories',
    permissions: [
      {
        value: 'admin.categories:view',
        label: 'View categories',
      },
      {
        value: 'admin.categories:edit',
        label: 'Edit categories',
      },
      {
        value: 'admin.categories:delete',
        label: 'Delete categories',
      },
      {
        value: 'admin.categories:create',
        label: 'Create categories',
      },
    ],
  },
  {
    feature: 'Legal management',
    prefix: 'admin.legal',
    permissions: [
      {
        value: 'admin.legal:view',
        label: 'View Legal',
      },
    ],
  },
  {
    feature: 'Agreement management',
    prefix: 'admin.agreement',
    permissions: [
      {
        value: 'admin.agreement:view',
        label: 'View agreement',
      },
      {
        value: 'admin.agreement:edit',
        label: 'Edit agreement',
      },
      {
        value: 'admin.agreement:delete',
        label: 'Delete agreement',
      },
      {
        value: 'admin.agreement:create',
        label: 'Create agreement',
      },
    ],
  },
  {
    feature: 'File management',
    prefix: 'admin.file',
    permissions: [
      {
        value: 'admin.file:view',
        label: 'View file',
      },
      {
        value: 'admin.file:edit',
        label: 'Edit file',
      },
      {
        value: 'admin.file:delete',
        label: 'Delete file',
      },
      {
        value: 'admin.file:create',
        label: 'Create file',
      },
    ],
  },
  {
    feature: 'Role management',
    prefix: 'admin.role',
    permissions: [
      {
        value: 'admin.role:view',
        label: 'View role',
      },
      {
        value: 'admin.role:edit',
        label: 'Edit role',
      },
      {
        value: 'admin.role:delete',
        label: 'Delete role',
      },
      {
        value: 'admin.role:create',
        label: 'Create role',
      },
    ],
  },
  {
    feature: 'Approval management',
    prefix: 'admin.approval',
    permissions: [
      {
        value: 'admin.approval:view',
        label: 'View approval',
      },
      {
        value: 'admin.approval:edit',
        label: 'Edit approval',
      },
      {
        value: 'admin.approval:delete',
        label: 'Delete approval',
      },
      {
        value: 'admin.approval:create',
        label: 'Create approval',
      },
    ],
  },
  {
    feature: 'Account management',
    prefix: 'admin.account',
    permissions: [
      {
        value: 'admin.account:view',
        label: 'View account',
      },
      {
        value: 'admin.account:edit',
        label: 'Edit account',
      },
      {
        value: 'admin.account:delete',
        label: 'Delete account',
      },
      {
        value: 'admin.account:create',
        label: 'Create account',
      },
    ],
  },
  {
    feature: 'Custom Contract management',
    prefix: 'admin.custom',
    permissions: [
      {
        value: 'admin.custom:view',
        label: 'View custom contract',
      },
      {
        value: 'admin.custom:edit',
        label: 'Edit custom contract',
      },
      {
        value: 'admin.custom:delete',
        label: 'Delete custom contract',
      },
      {
        value: 'admin.custom:create',
        label: 'Create custom contract',
      },
    ],
  },
  {
    feature: 'Ticket management',
    prefix: 'admin.ticket',
    permissions: [
      {
        value: 'admin.ticket:view',
        label: 'View tickets',
      },
      {
        value: 'admin.ticket:edit',
        label: 'Edit tickets',
      },
      {
        value: 'admin.ticket:delete',
        label: 'Delete tickets',
      },
      {
        value: 'admin.ticket:create',
        label: 'Create tickets',
      },
    ],
  },
];

export const adminRole = {
  id: 'admin',
  name: 'Admin',
  description: 'Admin role',
  permissions: [
    'admin.dashboard:*',
    'admin.team:*',
    'admin.role:*',
    'admin.file:*',
    'admin.task:*',
    'admin.agreement:*',
    'admin.account:*',
    'admin.legal:*',
    'admin.custom:*',
    'admin.ticket:*',
    'admin.approval:*',
    'admin.clause:*',
    'admin.counterparty:*',
    'admin.categories:*',
  ],
} as Role;

export const memberRole = {
  id: 'member',
  name: 'Member',
  description: 'Member role',
  permissions: [
    'admin.dashboard:view',
    'admin.team:view',
    'admin.task:*',
    'admin.agreement:*',
    'admin.file:*',
  ],
} as Role;

// define default roles
export const defaultRoles = {
  admin: adminRole,
  member: memberRole,
};
