import React, { forwardRef, useCallback, useMemo, Ref } from 'react';
import * as RadixSelect from '@radix-ui/react-select';
import { Label } from '@radix-ui/react-label';
import { cn, createKey } from '@/lib/utils';
import { CheckIcon, ChevronDownIcon } from '@radix-ui/react-icons';

type Option<V extends string> = {
  value: V;
  label: string;
  disabled?: boolean;
};

type OptionGroup<V extends string> = {
  label: string;
  options: Option<V>[];
};

type OptionSeparator = 'separator';

type SelectItemProps = {
  className?: string;
  children: React.ReactNode;
  value: string;
  disabled?: boolean;
};

const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <RadixSelect.Item
        className={cn(
          'text-sm flex h-9 items-center px-6 py-5 cursor-default select-none outline-none relative overflow-hidden',
          'data-[highlighted=]:bg-secondary/50 data-[disabled=]:pointer-events-none data-[disabled=]:opacity-50',
          className,
        )}
        {...props}
        ref={forwardedRef}
      >
        <RadixSelect.ItemText>{children}</RadixSelect.ItemText>
        <RadixSelect.ItemIndicator className='SelectItemIndicator'>
          <CheckIcon />
        </RadixSelect.ItemIndicator>
      </RadixSelect.Item>
    );
  },
);

SelectItem.displayName = 'SelectItem';

type WrapperExtraProps = {
  error?: string;
};

type WrapperProps = {
  children: React.ReactNode;
  id?: string;
  label?: string;
} & WrapperExtraProps;

const Wrapper = ({ id, label, error, children }: WrapperProps) => {
  if (!label && !error) {
    return <>{children}</>;
  }
  return (
    <div className='flex flex-col gap-2'>
      {label && (
        <Label htmlFor={id} className='text-sm font-normal text-muted-foreground'>
          {label}
        </Label>
      )}
      <div className='flex flex-col gap-1'>
        {children}
        {error && <small className='text-sm font-normal text-[tomato]'>{error}</small>}
      </div>
    </div>
  );
};

type SelectProps<V extends string> = {
  id?: string;
  label?: string;
  placeholder?: string;
  className?: string;
  contentClassName?: string;
  options: (Option<V> | OptionGroup<V> | OptionSeparator)[];
  children?: React.ReactNode;
  value?: V;
  defaultValue?: V;
  onValueChange?: (value: V) => void;
  disabled?: boolean;
  name?: string;
  popper?: boolean;
  noPortal?: boolean;
  isDisabled?: boolean;
} & WrapperExtraProps;

const BaseSelectWithoutRef = <V extends string>(
  {
    id,
    label,
    className,
    contentClassName,
    options,
    children,
    placeholder,
    error,
    // disabled, Not used
    isDisabled,
    popper = false,
    noPortal = false,
    ...props
  }: SelectProps<V>,
  ref: Ref<HTMLDivElement>,
) => {
  const Portal = !noPortal ? RadixSelect.Portal : React.Fragment;

  const renderGroup = useCallback((group: OptionGroup<V>) => {
    return (
      <RadixSelect.Group>
        <RadixSelect.Label className='SelectLabel'>{group.label}</RadixSelect.Label>
        {group.options.map((option) => (
          <SelectItem key={option.value} value={option.value} disabled={option.disabled}>
            {option.label}
          </SelectItem>
        ))}
      </RadixSelect.Group>
    );
  }, []);

  const renderedContent = useMemo(() => {
    if (Array.isArray(options)) {
      return options.map((option) => {
        if (option === 'separator') {
          return (
            <RadixSelect.Separator
              className='h-px bg-muted-background m-2'
              key={createKey('sep')}
            />
          );
        }
        if ('options' in option) {
          return <React.Fragment key={option.label}>{renderGroup(option)}</React.Fragment>;
        }
        return (
          <SelectItem key={option.value} value={option.value} disabled={option.disabled}>
            {option.label}
          </SelectItem>
        );
      });
    }
    return children;
  }, [options, children, renderGroup]);

  return (
    <>
      {/* <div>{renderedContent}</div> */}
      <Wrapper id={id} label={label} error={error}>
        <RadixSelect.Root {...props}>
          <RadixSelect.Trigger
            id={id}
            ref={ref as any}
            className={cn(
              'flex items-center justify-between h-10 w-full rounded-xl border border-input bg-transparent text-sm shadow-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50 data-[state=open]:ring-2 data-[state=open]:ring-ring data-[state=open]:ring-offset-1',
              className,
            )}
            aria-label={label}
            disabled={isDisabled}
          >
            <div className='px-6 py-5'>
              <RadixSelect.Value placeholder={placeholder} />
            </div>
            <div className='px-3'>
              <RadixSelect.Icon>
                <ChevronDownIcon />
              </RadixSelect.Icon>
            </div>
          </RadixSelect.Trigger>
          <Portal>
            <RadixSelect.Content
              className={cn(
                'shadow-md border border-input rounded-xl bg-background z-50',
                popper &&
                  'w-[var(--radix-select-trigger-width)] max-h-[var(--radix-select-content-available-height)] z-10',
                contentClassName,
              )}
              {...(popper
                ? { position: 'popper', sideOffset: 8, align: 'center', collisionPadding: 8 }
                : {})}
            >
              {/* <BaseSelect.ScrollUpButton className="SelectScrollButton">
            <ChevronUpIcon />
          </BaseSelect.ScrollUpButton> */}

              <RadixSelect.Viewport>{renderedContent}</RadixSelect.Viewport>

              {/* <BaseSelect.ScrollDownButton className="SelectScrollButton">
            <ChevronDownIcon />
          </BaseSelect.ScrollDownButton> */}
            </RadixSelect.Content>
          </Portal>
        </RadixSelect.Root>
      </Wrapper>
    </>
  );
};

export const Select = forwardRef(BaseSelectWithoutRef);
export type { SelectProps };
