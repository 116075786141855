import { Permission } from '@/data/permission';

export const hasPermission = (permissions: Permission[], requiredPermissions: Permission[]) => {
  return (
    requiredPermissions.length === 0 ||
    requiredPermissions.some((p) => {
      const wildcardPermission = (p.split(':')[0] + ':*') as Permission;
      return permissions.includes(p) || permissions.includes(wildcardPermission);
    })
  );
};
