import { useControllableState } from '@/lib/hooks/state';
import { JsonPage } from './types';
import { Property } from './property';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useDatabase, useDatabaseListData } from 'reactfire';
import { cleanData, isDataValid, dateToString } from './utils';
import { FormBuilderContext } from './context';
import { ref as FirebaseRef } from 'firebase/database';
import { CounterPartyDTO } from '@/lib/definitions';
import { useCompany } from '../../providers/company';

type FormBuilderProps = {
  schema: JsonPage;
  data?: any;
  onChange?: (data: any) => void;
  extra?: {
    [key: string]: any;
  };
};

export type FormBuilderRef = {
  validate: () => boolean;
  getData: () => any;
};

export const FormBuilder = forwardRef<FormBuilderRef, FormBuilderProps>(
  ({ schema, data, onChange, extra = {} }, ref) => {
    const root = useRef<HTMLDivElement>(null);
    const [state, setState] = useControllableState({
      prop: data,
      defaultProp: {},
      onChange,
    });

    const db = useDatabase();
    const { activeCompany: company } = useCompany('FormBuilder');
    const counterPartyRef = FirebaseRef(db, `counterparties/${company.id}`);

    const { status, data: counterparties } = useDatabaseListData<CounterPartyDTO>(counterPartyRef);

    const [errors, setErrors] = useState<Record<string, string>>({});

    const calculateTotal = (items: any[]) => {
      if (Array.isArray(items) && items.length > 0) {
        return items
          .reduce((acc: any, item: any) => {
            const subTotal = parseFloat(item.subTotal || 0);
            return acc + (isNaN(subTotal) ? 0 : subTotal);
          }, 0)
          .toFixed(2);
      }
      return '0.00';
    };

    // Define a function to calculate subTotal values
    const calculateSubTotal = (formData: any) => {
      if (Array.isArray(formData.items)) {
        const updatedItems = formData.items.map((item: any) => {
          const unit = parseFloat(item.unit || 0);
          const unitPrice = parseFloat(item.unitPrice || 0);
          const subTotal = (unit * unitPrice).toFixed(2);

          return {
            ...item,
            subTotal,
          };
        });

        return {
          ...formData,
          items: updatedItems,
          totalValue: calculateTotal(updatedItems),
        };
      }

      return formData;
    };

    useImperativeHandle(
      ref,
      () => ({
        validate: () => {
          const [isValid, errors] = isDataValid(state, schema);
          if (!isValid && errors) {
            setErrors(errors);
            focusOnFirstError();
          }
          return isValid;
        },
        getData: () => {
          const formData = cleanData(state, schema);

          const updatedData = calculateSubTotal(formData);
          const totalValue = calculateTotal(updatedData.items);

          return {
            ...updatedData,
            totalValue,
          };
        },
      }),
      [state, schema],
    );

    const focusOnFirstError = () => {
      const firstError = root.current?.querySelector('[data-error="true"]');
      if (firstError) {
        if (firstError instanceof HTMLInputElement) {
          firstError.focus();
        } else {
          const input = firstError.querySelector('input');
          if (input) {
            input.focus();
          }
        }
      }
    };

    // this was done to resolve the issue of date not being set to today's date when multiple dates are on a page and update default values declared in schema
    // TODO: can be done better - this performs infinitely and affects performance
    Object.entries(schema.properties).forEach(([key, property]) => {
      if (property.type === 'date' && !state[key]) {
        setState((prev: any) => ({ ...prev, [key]: dateToString(new Date()) }));
      }
      if (
        property.type !== 'date' &&
        property.type !== 'array' &&
        property.type !== 'parties' &&
        !state[key] &&
        property.default
      ) {
        setState((prev: any) => ({ ...prev, [key]: property.default }));
        state[key] = property.default;
      }
    });

    return (
      <FormBuilderContext.Provider
        value={{
          extra,
          schema,
          state,
          counterparties: counterparties as CounterPartyDTO[],
          counterpartiesLoading: status === 'loading',
        }}
      >
        <div className='space-y-10' ref={root}>
          <header>
            <h2 className='text-2xl font-bold'>{schema.title}</h2>
            <p className='text-muted-foreground text-sm'>{schema.description}</p>
          </header>
          <div className='space-y-7'>
            {Object.entries(schema.properties).map(([key, property]) => (
              <Property
                key={key}
                name={key}
                property={property}
                value={state[key]}
                state={state}
                error={errors[key]}
                setError={(error) => {
                  setErrors((prev) => ({ ...prev, [key]: error }));
                }}
                onChange={(newValue) => {
                  setState((prev: any) => {
                    const nextValue = { ...prev };
                    if (key === 'items' && Array.isArray(newValue)) {
                      nextValue[key] = newValue.map((item: any) => {
                        const unit = parseFloat(item.unit || 0);
                        const unitPrice = parseFloat(item.unitPrice || 0);

                        const existingSubTotal = unit * unitPrice;
                        return {
                          ...item,
                          subTotal: (existingSubTotal || 0).toFixed(2),
                        };
                      });
                    } else {
                      if (newValue === undefined) {
                        delete nextValue[key];
                      } else {
                        nextValue[key] = newValue;
                      }
                    }

                    const updatedData = calculateSubTotal(nextValue);
                    const newTotal = calculateTotal(updatedData.items);

                    return {
                      ...updatedData,
                      newTotal,
                    };
                  });
                }}
              />
            ))}
          </div>
        </div>
      </FormBuilderContext.Provider>
    );
  },
);

FormBuilder.displayName = 'FormBuilder';
