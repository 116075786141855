import { Input, RadioGroup, Select, Calendar, PropertyWrapper, Textarea2 } from './component';
import {
  StringPropertyProps,
  NumberPropertyProps,
  BooleanPropertyProps,
  StringArrayPropertyProps,
  EnumPropertyProps,
  DatePropertyProps,
  ComputedPropertyProps,
} from './types';
import { TagInput } from '@/components/ui';
import { useEffect, useMemo, useState } from 'react';
import { dateToString } from './utils';
import { usePropertyValue } from './hooks';
import { renderTemplate } from '@/lib/templater';

export function InputProperty(props: StringPropertyProps) {
  const { property, value: _value, error, setError, onChange } = props;
  const [value, setValue] = useState(_value || '');

  const transformValue = (str: string) => {
    if (property.isUppercase) {
      return str.toUpperCase();
    } else if (property.isCapitalize) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      return str;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = transformValue(e.target.value);
    setValue(newValue);
    onChange(newValue);
    setError(undefined);
  };

  const title = useMemo(() => {
    // console.log('property', props.context);
    return renderTemplate(property.title ?? '', { self: props.context || {} });
  }, [property.title, props.context]);

  return (
    <Input
      value={value}
      error={error}
      onChange={handleChange}
      title={title}
      description={property.description}
    />
  );
}

export function TextareaProperty(props: StringPropertyProps) {
  const { property, value: _value, error, setError, onChange } = props;
  const [value, setValue] = usePropertyValue(_value, onChange, '');
  const transformValue = (str: string) => {
    if (property.isUppercase) {
      return str.toUpperCase();
    } else if (property.isCapitalize) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      return str;
    }
  };

  const handleChange = (newValue: string) => {
    const transformedValue = transformValue(newValue);
    setValue(transformedValue);
    onChange(transformedValue);
    if (error) {
      setError(undefined);
    }
  };

  const title = useMemo(() => {
    return renderTemplate(property.title ?? '', { self: props.state || {} });
  }, [property.title, props.state]);

  return (
    <Textarea2
      value={value}
      error={error}
      onValueChange={handleChange}
      title={title}
      description={property.description}
    />
  );
}

export function StringProperty(props: StringPropertyProps) {
  if (props.property.widget === 'textarea') {
    return <TextareaProperty {...props} />;
  }
  return <InputProperty {...props} />;
}

export function NumberProperty(props: NumberPropertyProps) {
  const { property, value: initialValue, error, setError, onChange } = props;

  const { min = 0, max } = property;
  // Convert initial value to a number (if it's a string, else keep it as is)
  const initialValueAsNumber = initialValue ? Number(initialValue.toString().replace(/,/g, '')) : 0;

    // Use initial value for state initialization
    const [inputValue, setInputValue] = useState<string>(initialValueAsNumber ? new Intl.NumberFormat().format(initialValueAsNumber) : '');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInputValue(value);
  
    // Try to parse the value to a number (remove commas before parsing)
    const parsedValue = Number(value.replace(/,/g, '')); // Remove commas
    
    if (!isNaN(parsedValue) && parsedValue >= min && (max === undefined || parsedValue <= max)) {
      // Format the number with commas using Intl.NumberFormat and send the formatted string
      const formattedValue = new Intl.NumberFormat().format(parsedValue);
      onChange(formattedValue); // Send formatted string to parent
  
      setError(undefined); // Clear any previous errors
    } else {
      setError('Invalid number');
    }
  };
  

  return (
    <Input
      type="text"
      inputMode="decimal"
      max={max}
      min={min}
      step="any"
      value={inputValue}
      error={error}
      onChange={handleInputChange}
      title={property.title}
      description={property.description}
    />
  );
}

const getBooleanValue = (value: boolean | undefined) => {
  if (value === undefined) {
    return undefined;
  } else if (value === true) {
    return 'yes';
  } else if (value === false) {
    return 'no';
  }
  return 'unset';
};

export function BooleanProperty(
  props: BooleanPropertyProps & { setError: (error: string | boolean | undefined) => void },
) {
  const { property, name, value: _value, onChange, error, setError } = props;

  // Track whether the "entity" validation should trigger
  const isEntityRequired = _value === true;

  const [value, setValue] = usePropertyValue<'yes' | 'no' | 'unset'>(
    getBooleanValue(_value),
    (newValue) => {
      // Avoid unnecessary state updates
      if (newValue !== value) {
        if (newValue === 'yes') {
          onChange(true);
        } else if (newValue === 'no') {
          onChange(false);
        }
        if (newValue !== 'unset') {
          setError(undefined);
        }
      }
    },
    getBooleanValue(property.default) ?? 'unset',
  );

  useEffect(() => {
    if (value !== 'unset') {
      if (value === 'yes') {
        onChange(true);
      } else {
        onChange(false);
      }
      setError(undefined);
    }
  }, [value, onChange, setError]);

  // Handle validation: only trigger error if required and the user selects 'yes'
  useEffect(() => {
    if (isEntityRequired && !value) {
      setError('This field is required for entities.');
    }
  }, [isEntityRequired, value, setError]);

  const title = useMemo(() => {
    return renderTemplate(property.title ?? '', { self: props.context || {} });
  }, [property.title, props.context]);

  return (
    <div className='flex flex-col gap-2'>
      <PropertyWrapper title={title} description={property.description} error={error}>
        <RadioGroup.Root
          className='grid grid-cols-2'
          role='radiogroup'
          value={value}
          onValueChange={(newValue) => {
            if (newValue !== value) {
              setValue(newValue as 'yes' | 'no');
            }
          }}
          id={name}
        >
          {property.reverse ? (
            <>
              <RadioGroup.Input label={property.yesLabel ?? 'Yes'} value='yes' />
              <RadioGroup.Input label={property.noLabel ?? 'No'} value='no' />
            </>
          ) : (
            <>
              <RadioGroup.Input label={property.noLabel ?? 'No'} value='no' />
              <RadioGroup.Input label={property.yesLabel ?? 'Yes'} value='yes' />
            </>
          )}
        </RadioGroup.Root>
      </PropertyWrapper>
    </div>
  );
}

export function TagInputProperty(props: StringArrayPropertyProps) {
  const { property, value: _value, onChange, error } = props;
  const [value, setValue] = usePropertyValue(_value, onChange, []);
  return <TagInput label={property.title} value={value} onChange={setValue} error={error} />;
}

export function EnumProperty(props: EnumPropertyProps) {
  const { property, value: _value, onChange, error, setError } = props;

  const handleChange = (newValue: string) => {
    onChange(newValue); // Trigger onChange for Firebase sync
    setError(undefined);
  };

  return (
    <Select
      value={_value || ''}
      label={property.title}
      options={property.enum?.map((op) => ({ label: op[1], value: op[0] })) || []}
      onValueChange={handleChange}
      error={error}
    />
  );
}


export function DateProperty(props: DatePropertyProps) {
  const { property, value: _value, onChange, error } = props;
  const [value, setValue] = usePropertyValue<string>(_value, onChange, undefined as any);
  useEffect(() => {
    setValue(dateToString(new Date())); // set the default value to be today's date
  }, []);
  return (
    <Calendar
      title={property.title}
      error={error}
      value={value ? new Date(value) : new Date()}
      onValueChange={(date) => setValue(dateToString(date))}
    />
  );
}

export function ComputedProperty(props: ComputedPropertyProps) {
  const { property, value: _value, onChange, state } = props;
  const [value, setValue] = usePropertyValue(_value, onChange, 0);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    const parseValue = (val: string | number | null | undefined) => {
      if (val == null) return 0;
      if (typeof val === 'number') return val;
      // Remove currency symbol and commas, then parse
      return parseFloat(val.replace(/[^0-9.-]+/g, '') || '0');
    };

    let result: number;
    const value1 = parseValue(state[property.value1]);
    const value2 = parseValue(state[property.value2]);

    try {
      switch (property.operation) {
        case '+':
          result = value1 + value2;
          break;
        case '-':
          result = value1 - value2;
          break;
        case '*':
          result = value1 * value2;
          break;
        case '/':
          if (value2 === 0) throw new Error('Division by zero');
          result = value1 / value2;
          break;
        default:
          result = 0;
      }

      // For bulk operations
      if (property.bulkValues) {
        property.bulkValues.forEach((ref) => {
          const bulkValue = parseValue(state[ref]);
          switch (property.operation) {
            case '+':
              result += bulkValue;
              break;
            case '-':
              result -= bulkValue;
              break;
            // Multiply and divide are not used for bulk operations
          }
        });
      }

      // Handle special case for tax calculation
      if (property.title === 'Tax Amount') {
        result = (value1 * value2) / 100;
      }

      // Round to 2 decimal places
      result = Math.round(result * 100) / 100;

      setValue(result);
      setErrorMessage(undefined);
    } catch (err) {
      setErrorMessage(err instanceof Error ? err.message : 'Calculation error');
    }
  }, [state, property]);

  return (
    <Input
      type='text'
      value={value.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}
      error={errorMessage}
      onChange={() => {}} // Read-only
      title={property.title}
      description={property.description}
      disabled
    />
  );
}
